import "./css/app-notification-modal.css";
import CloseBtn from "../../img/Close-Square.svg";
import SNotify from "../../img/successNotify.svg";
import RejectedIcon from "../../img/rejected.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AppNotificationModal({
  notify,
  handleModalUpload,
  setToggle,
}) {
  const [display1, setDisplay] = useState("");

  const navigate = useNavigate();

  const closeDisplay1 = () => {
    setDisplay("deactivate-close");
    setToggle(0);
    // handleModalUpload();

    navigate("/app-content");
  };
  return (
    <>
      <div
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        className={`app-notification-display-detailss ${display1}`}
      >
        <div className={`app-notification-notification`}>
          <div className="app-notification-notification-flexedcont">
            <img
              src={SNotify}
              loading="lazy"
              width="63"
              height="63"
              alt=""
              className="app-notification-notification-image"
            />
            <div className="app-notification-notification-text-block-2">
              {notify}
            </div>
            <a
              href="#/"
              className="app-notification-notification-button app-notification-notification-w-button"
              // onClick={closeDisplay1}
              onClick={closeDisplay1}
            >
              <strong>Close</strong>
            </a>
          </div>
          <img
            onClick={closeDisplay1}
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt=""
            className="app-notification-notification-image-2"
          />
        </div>
      </div>
    </>
  );
}

export const RoleCreationSuccess = ({ notify, setToggle }) => {
  const [display1, setDisplay] = useState("");

  const navigate = useNavigate();

  const closeDisplay1 = () => {
    setDisplay("deactivate-close");
    setToggle(0);
    navigate("/user-control");
  };
  return (
    <>
      <div
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        className={`app-notification-display-detailss ${display1}`}
      >
        <div className={`app-notification-notification`}>
          <div className="app-notification-notification-flexedcont">
            <img
              src={SNotify}
              loading="lazy"
              width="63"
              height="63"
              alt=""
              className="app-notification-notification-image"
            />
            <div className="app-notification-notification-text-block-2">
              {notify}
            </div>
            <a
              href="#/"
              className="app-notification-notification-button app-notification-notification-w-button"
              onClick={closeDisplay1}
            >
              <strong>Close</strong>
            </a>
          </div>
          <img
            onClick={closeDisplay1}
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt=""
            className="app-notification-notification-image-2"
          />
        </div>
      </div>
    </>
  );
};
export const RoleCreationError = ({ notify, setToggle, close }) => {
  const [display1, setDisplay] = useState("");
  const navigate = useNavigate();

  const closeDisplay1 = () => {
    setDisplay("deactivate-close");
    setToggle(0);
    if (close !== "close") {
      navigate("/kyc");
    }
  };
  return (
    <>
      <div
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        className={`app-notification-display-detailss ${display1}`}
      >
        <div className={`app-notification-notification`}>
          <div className="app-notification-notification-flexedcont">
            <img
              src={RejectedIcon}
              loading="lazy"
              width="63"
              height="63"
              alt=""
              className="app-notification-notification-image"
            />
            <div className="app-notification-notification-text-block-2">
              {notify}
            </div>
            <a
              href="#/"
              className="app-notification-notification-button app-notification-notification-w-button"
              onClick={closeDisplay1}
            >
              <strong>Close</strong>
            </a>
          </div>
          <img
            onClick={closeDisplay1}
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt=""
            className="app-notification-notification-image-2"
          />
        </div>
      </div>
    </>
  );
};
