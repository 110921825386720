import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { SupportActions } from "../../state/actions";

import Pagination from "../../components/footer-pagination";

// let pageSize = 6;

export default function SupportSearchResult({ search }) {
  const [currentPage, setCurrentPage] = useState(1);

  const { profileSearch, profileSearchMeta, error, loading } = useSelector(
    (state) => state.support
  );

  const dispatch = useDispatch();
  const { searchUsers } = bindActionCreators(SupportActions, dispatch);

  useEffect(() => {
    if (search.trim() !== "") {
      searchUsers(search, currentPage);
    }
    // eslint-disable-next-line
  }, [search, currentPage]);

  const { per_page, total } = profileSearchMeta;

  let userData = profileSearch;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();

  const navigatePage = (e, id) => {
    e.preventDefault();
    const newID = btoa(id);
    navigate(`/profile-review/${newID}`);
  };

  return (
    <>
      <div className="supportDiv">
        <div className="profile-search-support-table-content">
          <table className="support-table">
            <tr>
              <th></th>
              <th>NAME</th>
              <th>BVN</th>
              <th>EMAIL</th>
              <th>PHONE NO</th>
            </tr>
            {userData.map((item, idx) => (
              <tr key={idx} className="support-table-tr">
                <td>{(currentPage - 1) * per_page + 1 + idx}</td>
                <td>{item.name}</td>
                <td>{(item.kyc && item.kyc.bvn_number) || "-"}</td>
                <td>{item.email}</td>
                <td>{item.telephone}</td>
                <td>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={(e) => navigatePage(e, item.id)}
                    className="kbtn"
                  >
                    View
                  </span>
                </td>
              </tr>
            ))}
          </table>
        </div>
        {!loading && !error.top && userData.length > 0 && (
          <Pagination
            className="support-ticket-pagination-bar"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
