import * as types from "../types";

const initialState = {
  users: [],
  usersMeta: {},
  roles: [],
  rolesMeta: {},
  error: {},
  toggle: 0,
  loading: true,
  userLoading: true,
  roleLoading: true,
  activeCard: 0,
  cardSize: true,
  subUrl: "",
  searchUserData: null,
  searchUsersMeta: null,
  searchRoleData: null,
  searchRoleMeta: null,
  loginHistory: null,
  loginHistoryLoading: true,
  userID: 0,
  roleID: 0,
  userDetail: {},
  createUserLoading: false,
  createRoleLoading: false,
};

const userControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ROLES:
      return {
        ...state,
        roles: action.payload,
        rolesMeta: action.meta,
        loading: false,
        roleLoading: false,
      };
    case types.FETCH_ROLES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.DELETE_ROLE:
      return {
        ...state,
        toggle: action.toggle,
        loading: false,
        roles: {
          ...state.roles,
          data: state.roles.data.filter((role) => role.id !== action.payload),
        },
        createRoleLoading: false,
      };
    case types.DELETE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        createRoleLoading: false,
      };
    case types.SET_TOGGLE:
      return {
        ...state,
        toggle: action.payload,
        userID: action.id,
        loading: false,
      };
    case types.SET_ROLE_ID:
      return {
        ...state,
        toggle: action.payload,
        roleID: action.id,
        loading: false,
      };
    case types.SET_ACTIVE_CARD:
      return {
        ...state,
        activeCard: action.payload.id,
        cardSize: false,
        subUrl: action.payload.name,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: true,
        userLoading: true,
      };
    case types.UPDATE_USER:
      return {
        ...state,
        toggle: action.value,
        loading: false,
        createUserLoading: false,
        error: "",
        users: {
          ...state.users,
          data: state.users.data.map((user) =>
            user.id === action.payload.id ? action.payload : user
          ),
        },
      };
    case types.UPDATE_ROLE:
      return {
        ...state,
        toggle: action.value,
        loading: false,
        createRoleLoading: false,
        error: "",
        roles: {
          ...state.roles,
          data: state.roles.data.map((role) =>
            role.id === action.payload.id ? action.payload : role
          ),
        },
      };
    case types.CREATE_USER:
      action.payload.status = "active";
      return {
        ...state,
        toggle: action.value,
        error: "",
        users: {
          ...state.users,
          data: [action.payload, ...state.users.data],
        },
        createUserLoading: false,
      };
    case types.CREATE_ROLE:
      return {
        ...state,
        toggle: action.value,
        error: "",
        loading: false,
        roles: {
          ...state.roles,
          data: [action.payload, ...state.roles.data],
        },
        createRoleLoading: false,
      };
    case types.CREATE_ROLE_ERROR:
      return {
        ...state,
        toggle: action.value,
        error: action.payload,
        loading: false,
        createRoleLoading: false,
      };
    case types.UPDATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        createUserLoading: false,
      };
    case types.CREATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        createUserLoading: false,
      };
    case types.SET_USER_LOADING:
      return {
        ...state,
        createUserLoading: true,
      };
    case types.SET_ROLE_LOADING:
      return {
        ...state,
        createRoleLoading: true,
      };
    case types.FETCH_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        userLoading: false,
      };
    case types.FETCH_USERS:
      return {
        ...state,
        users: action.payload,
        usersMeta: action.meta,
        loading: false,
        userLoading: false,
      };
    case types.FETCH_USER:
      return {
        ...state,
        userDetail: action.payload,
        loading: false,
        userLoading: false,
      };
    case types.FETCH_LOGIN_HISTORY:
      return {
        ...state,
        loginHistory: action.payload,
        loading: false,
        loginHistoryLoading: false,
      };
    case types.FETCH_LOGIN_HISTORY_ERROR:
      return {
        ...state,
        loginHistory: action.payload,
        loading: false,
        loginHistoryLoading: false,
        error: { err: "loading error" },
      };
    case types.SEARCH_USER:
      return {
        ...state,
        searchData:
          action.payload &&
          state.users.data.filter(
            (user) =>
              user.name.toLowerCase().includes(action.payload.toLowerCase()) ||
              user.email.toLowerCase().includes(action.payload.toLowerCase()) ||
              user.status
                .toLowerCase()
                .includes(action.payload.toLowerCase()) ||
              user.created_at
                .toLowerCase()
                .includes(action.payload.toLowerCase()) ||
              user.roles
                .map((r) => r.name)
                .join(", ")
                .toLowerCase()
                .includes(action.payload)
          ),
      };
    case types.SEARCH_ROLE:
      return {
        ...state,
        searchRoleData:
          action.payload &&
          state.roles.data.filter(
            (role) =>
              role.name.toLowerCase().includes(action.payload.toLowerCase()) ||
              (role.publisher &&
                role.publisher
                  .toLowerCase()
                  .includes(action.payload.toLowerCase()))
          ),
      };

    case types.DEACTIVATE_USER:
      return {
        ...state,
        toggle: action.toggle,
        loading: false,
        users: {
          ...state.users,
          data: state.users.data.map((user) => {
            if (user.id === state.userID) {
              user.status = "inactive";
              return user;
            }
            return user;
          }),
        },
      };
    case types.DEACTIVATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.REACTIVATE_USER:
      return {
        ...state,
        toggle: action.toggle,
        loading: false,
        users: {
          ...state.users,
          data: state.users.data.map((user) => {
            if (user.id === state.userID) {
              user.status = "active";
              return user;
            }
            return user;
          }),
        },
      };
    case types.REACTIVATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userControlReducer;
