import Cancel from "../btn/cancel";
import { Link } from "react-router-dom";


import "../../css/support/bank-card.css";
import { useState } from "react";
import { useSelector } from "react-redux";
export default function BankCard({ setToggle }) {
  const [close, setClose] = useState("")
  // const items = data;
  const handleClick = () => {
    setClose("close")
    setToggle(0)
  }

  const { banks } = useSelector((state) => state.support);
  return (
    <div className={`bank-card-container ${close}`}>
      <div className="bank-card-content">
        <div className="bank-card-cancel" onClick={handleClick}>
          <Cancel />
        </div>
        <p className="bank-card-title">Bank & Cards</p>

        <div>
          <div className="bank-section">
            <h5>Bank</h5>
            <hr />
            <table className="bank-card-table">
              <tr>
                <th>Account No</th>
                <th>Account Name</th>
                <th>Bank name</th>
                <th></th>
              </tr>
              {banks.map((bank) => <tr className="item-tr">
                  <td>{(bank.account_number) ||
                        "-"}</td>
                  <td>{(bank.account_name) ||
                        "-"}</td>
                  <td>{(bank.bank_name) ||
                        "-"}</td>
                  <td>
                    {/* <Link to="#" className="bank-card-btn"  */}
                    {/* // onClick={() => { setToggle(8) }} */}
                    {/* > */}
                      {/* <button className="btn-default">Delete</button>
                    </Link> */}
                  </td>
                </tr>)}
            
            </table>
          </div>
          <div className="card-section">
            <h5>Card(s)</h5>
            <hr />
            <table className="bank-card-table">
              <tr>
                <th>#</th>
                <th>Card No</th>
                <th>Card type</th>
                <th></th>
              </tr>
              {data2.map((item) => (
                <tr className="item-tr">
                  <td>{item.accountNumber}</td>
                  <td>{item.account}</td>
                  <td>{item.bank}</td>
                  <td>
                    <Link to="#" onClick={() => { setToggle(8) }} className="bank-card-btn">
                      <button>Delete</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const data2 = [
  // {
  //   accountNumber: 123456789,
  //   account: "wade warren chukwuma",
  //   bank: "Zenith bank",
  // },
  // {
  //   accountNumber: 123456789,
  //   account: "wade warren chukwuma",
  //   bank: "Zenith bank",
  // },
  // {
  //   accountNumber: 123456789,
  //   account: "wade warren chukwuma",
  //   bank: "Zenith bank",
  // },
];
