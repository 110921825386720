// import { useState } from "react";
import "./card.css";
import KycLevel2 from "../kyc/kyc-table/kyc-table";
import KycAgency from "../kyc/kyc-table/agency-kyc-table";
import KycVerification from "../kyc/kyc-table/kyc-table2";
import Kycimg from "../kyc/kyc-img/kyc-img";
import KycLevel1Table from "../kyc/kyc-table/kyc-table_level1";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { KYC } from "../../state/actions";
import PasswordReset from "../kyc/kyc-table/password-reset";
import PinReset from "../kyc/kyc-table/pin-reset";
import Spinner from "../../pages/login/spinner";

export default function Card({ setHidden, setText, setUserID }) {
  // const [active, setActive] = useState(0);

  const dispatch = useDispatch();

  const { setActiveCard } = bindActionCreators(KYC, dispatch);
  const {
    kycLevelZeroMeta,
    kycLevelOneMeta,
    kycLevelTwoMeta,
    kycPasswordVerificationMeta,
    kycPinVerificationMeta,
    kycAgencyMeta,
    // kycVerificationMeta,
    loading,
    activeCard,
  } = useSelector((state) => state.kyc);
  const prps = [
    {
      id: 1,
      level: "Level 1",
      num: kycLevelZeroMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: 2,
      level: "Level 2",
      num: kycLevelOneMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: 3,
      level: "Agency",
      num: kycAgencyMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: 4,
      level: "Address Verification",
      num: kycLevelTwoMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: 5,
      level: "Password Reset",
      num: kycPasswordVerificationMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: 6,
      level: "Pin Reset",
      num: kycPinVerificationMeta.total || 0,
      numText: "Total Records",
    },
  ];

  function handleActive(id, text) {
    setActiveCard(id, "/" + text);
    setText("/" + text);
  }

  function renderTable() {
    if (activeCard === 0) {
      return <Kycimg />;
    } else if (activeCard === 1) {
      return <KycLevel1Table />;
    } else if (activeCard === 2) {
      return <KycLevel2 />;
    } else if (activeCard === 3) {
      return <KycAgency setHidden={setHidden} />;
    } else if (activeCard === 4) {
      return <KycVerification setHidden={setHidden} setUserID={setUserID} />;
    } else if (activeCard === 5) {
      return <PasswordReset setHidden={setHidden} />;
    } else if (activeCard === 6) {
      return <PinReset setHidden={setHidden} />;
    }
  }
console.log(loading)
  return (
    <>
      {loading && <div className="user-preloader"><Spinner /></div>}
      <div className="kyc-card-cont">
        {prps.map((item, index) => (
          <div
            className={activeCard === item.id ? `kyc-card active` : `kyc-card`}
            key={index}
            onClick={() => handleActive(item.id, item.level)}
          >
            <p style={{ width: "131px", fontSize: "12px" }}>{item.level}</p>
            <div className="kyc-record-box">
              <h2>{item.num}</h2>
              <p>{item.numText}</p>
            </div>
          </div>
        ))}
      </div>
      {renderTable()}
    </>
  );
}
