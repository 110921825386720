import React from "react";
import "../../../css/user-control/role-delete.css";
import CloseBtn from "../../../img/Close-Square.svg";
import ProfileActivate from "../../../img/profileactivate.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userControlActions } from "../../../state/actions";

function UserControlDeleteModal({ handleReject, value, content }) {
  const { roleID, createRoleLoading, error } = useSelector((state) => state.userControl);
  const dispatch = useDispatch();
  const { DeleteRole, setError } = bindActionCreators(userControlActions, dispatch);


  let close = "";
  const [display, setDisplay] = useState("");
  const closeDisplay = () => {
    if (close === null) {
      setDisplay("");
      setError({})
      handleReject(0);
    } else {
      setDisplay("close");
      setError({})
      handleReject(0);
    }
  };

  const handleDeleteRole = () => {
    DeleteRole(roleID, value);
  };

  return (
    <>
      <div className={`display-details ${display}`}>
        <div class={`role-delete `}>
          <div onClick={closeDisplay}>
            <strong class="role-delete-bold-text">Cancel</strong>
          </div>
          <div class="role-delete-flexedcont">
            <img
              src={ProfileActivate}
              loading="lazy"
              width="63"
              height="63"
              alt=""
              class="role-delete-image"
            />
            <div class="role-delete-text-block-2">
              {error.roleErr ? error.roleErr : content}
            </div>

            {error.roleErr ? null : (
              <button
                className="role-delete-button role-delete-w-button"
                onClick={handleDeleteRole}
                disabled={createRoleLoading}
              >
                <strong>Delete</strong>
              </button>
            )}
          </div>
          <img
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt=""
            class="role-delete-image-2"
            onClick={closeDisplay}
          />
        </div>
      </div>
    </>
  );
}

export default UserControlDeleteModal;
