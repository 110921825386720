import { useSelector } from 'react-redux'

import '../../css/cash-provider.css'

export default function CashProvider(){
    const { data } = useSelector((state) => state.dashboard)
    return(
        <div className='dashboard-card-color cash-provider'>
            <p>Current Active Cash Provider</p>
            <h2>{data !== null ? data.current_active_cash_provider : 0}</h2>
        </div>
    )
}