import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import "../../css/settlements/card.css";
import Spinner from "../../pages/login/spinner";
import { SettlementActions } from "../../state/actions";
import Kycimg from "../kyc/kyc-img/kyc-img";
import AppNotificationModal from "../vendorModals/appNotificationModal";
import FailedTransaction from "./failed_transaction";
import SettlementBillsHistory from "./settlement-bills-history";
import SettlementCashRequestHistory from "./settlement-cash-request-history";

export default function SettlementCard({ setText }) {
  const [active, setActive] = useState(0);
  const [cardSize, setCardSize] = useState(true);

  const [toggle, setToggle] = useState(0);

  const { cashRequestMeta, billHistoryMeta, failedTransactionMeta, failedTransactionLoading } = useSelector(
    (state) => state.settlement
  );
  const dispatch = useDispatch();
  const { FetchCashRequests, FetchBills, FetchFailedTransactions } = bindActionCreators(SettlementActions, dispatch);

  useEffect(() => {
    FetchCashRequests();
    FetchBills();
    FetchFailedTransactions();

    // eslint-disable-next-line
  }, []);

  const prps = [
    {
      id: "1",
      level: "Failed Transactions",
      num: failedTransactionMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: "2",
      level: "Bills History",
      num: billHistoryMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: "3",
      level: "Cash Request History",
      num: cashRequestMeta.total || 0,
      numText: "Total Records",
    },
  ];

  function handleActive(id) {
    setActive(id);
    setCardSize(false);
    if (id === "1") {
      setText("/Failed Transactions");
    } else if (id === "2") {
      setText("/Bills History");
    } else if (id === "3") {
      setText("/Cash Request History");
    }
    console.log(id);
  }

  function renderTable() {
    if (active === 0) {
      return <Kycimg />;
    } else if (active === "1") {
      return <FailedTransaction acceptToggle={setToggle} />;
    } else if (active === "2") {
      return <SettlementBillsHistory />;
    } else if (active === "3") {
      return <SettlementCashRequestHistory />;
    }
  }
  const handleToggle = () => {
    if (toggle === 0) {
      return null;
    }
    if (toggle === 2) {
      return (
        <div className="close-deactivate">
          <AppNotificationModal
            notify={"Transaction has been successfully settled"}
            setToggle={setToggle}
          />
        </div>
      );
    }
  };
  return (
    <>
      {(failedTransactionLoading) && <div className="user-preloader"><Spinner /></div>}
      {cardSize ? (
        <div className="card-cont fix">
          {prps.map((item, index) => (
            <div
              className="card"
              key={index}
              onClick={() => handleActive(item.id)}
            >
              <h2 style={{ width: "100%", fontSize: "16px" }}>{item.level}</h2>
              <div className="record-box">
                <h2>{item.num}</h2>
                <p>{item.numText}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="settlement-card-cont-2">
          {prps.map((item, index) => (
            <div
              className={active === item.id ? `card-2 active` : `card-2`}
              key={index}
              onClick={() => handleActive(item.id)}
            >
              <p style={{ width: "80%", marginTop: "8px" }}>{item.level}</p>

              <div className="record-box1">
                <h2>{item.num}</h2>
              </div>
            </div>
          ))}
        </div>
      )}
      {handleToggle()}
      {renderTable()}
    </>
  );
}
