import * as types from "../types";

const initialState = {
  kycLevelZero: [],
  kycLevelOne: [],
  kycLevelZeroMeta: {},
  kycLevelOneMeta: {},
  kycLevelTwoMeta: {},
  kycLevelTwo: [],
  kycAgencyMeta: {},
  kycAgency: [],
  kycVerification: [],
  kycVerificationMeta: {},
  kycPasswordVerification: [],
  kycPasswordVerificationMeta: {},
  kycPinVerification: [],
  kycPinVerificationMeta: {},
  kycUser: "",
  searchData: null,
  searchData0: null,
  searchKyc0Data: null,
  searchKyc0Meta: null,
  searchKyc1Data: null,
  searchKyc1Meta: null,
  searchKycAgencyData: null,
  searchKycAgencyMeta: null,
  searchKycVerificationData: null,
  searchKycVerificationMeta: null,
  searchKycPasswordVerificationData: null,
  searchKycPasswordVerificationMeta: null,
  searchKycPinVerificationData: null,
  searchKycPinVerificationMeta: null,
  searchLevel2Data: null,
  searchAddrVerification: null,
  error: {},
  loading: true,
  levelTwoLoading: true,
  levelOneLoading: true,
  levelZeroLoading: true,
  toggle: 0,
  activeCard: 0,
  subUrl: "",
};

const KYCReduce = (state = initialState, action) => {
  switch (action.type) {
    case types.APPROVE_KYC:
      return {
        ...state,
        toggle: action.toggle,
      };
    case types.SET_CARD_ACTIVE:
      return {
        ...state,
        activeCard: action.payload.id,
        subUrl: action.payload.url,
      };
    case types.REJECT_KYC:
      return {
        ...state,
        toggle: action.toggle,
      };
    case types.APPROVE_KYC_ERROR:
      return {
        ...state,
        toggle: action.toggle,
        error: action.payload,
        levelZeroLoading: false,
        levelOneLoading: false,
        levelTwoLoading: false,
      };
    case types.FETCH_KYC_LEVEL_ZERO:
      return {
        ...state,
        kycLevelZero: action.payload,
        kycLevelZeroMeta: action.meta,
        loading: false,
        levelZeroLoading: false,
      };
    case types.FETCH_PASSWORD_VERIFICATION:
      return {
        ...state,
        kycPasswordVerification: action.payload,
        kycPasswordVerificationMeta: action.meta,
        loading: false,
        levelZeroLoading: false,
      };
    case types.FETCH_PIN_VERIFICATION:
      return {
        ...state,
        kycPinVerification: action.payload,
        kycPinVerificationMeta: action.meta,
        loading: false,
        levelZeroLoading: false,
      };
    case types.FETCH_KYC_LEVEL_ONE:
      return {
        ...state,
        kycLevelOne: action.payload.filter((user) => user.bvn !== null),
        kycLevelOneMeta: action.meta,
        loading: false,
        levelOneLoading: false,
      };
    case types.FETCH_KYC_LEVEL_TWO:
      return {
        ...state,
        kycLevelTwo: action.payload,
        kycLevelTwoMeta: action.meta,
        loading: false,
        levelTwoLoading: false,
      };
    case types.FETCH_KYC_AGENCY:
      return {
        ...state,
        kycAgency: action.payload,
        kycAgencyMeta: action.meta,
        loading: false,
        levelTwoLoading: false,
      };
    case types.FETCH_KYC_VERIFICATION:
      return {
        ...state,
        kycVerification: action.payload,
        kycVerificationMeta: action.meta,
        loading: false,
      };
    case types.SET_USER: {
      return {
        ...state,
        kycUser: action.payload,
        loading: false,
      };
    }
    case types.SET_KYC_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.FETCH_KYC_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
    case types.SEARCH_KYC0:
      return {
        ...state,
        searchKyc0Data: action.payload,
        searchKyc0Meta: action.meta,
      };
    case types.SEARCH_KYC1:
      return {
        ...state,
        searchKyc1Data: action.payload,
        searchKyc1Meta: action.meta,
      };
    case types.SEARCH_KYC2:
      return {
        ...state,
        searchKycAgencyData: action.payload,
        searchKycAgencyMeta: action.meta,
      };
    case types.SEARCH_ADDR_VERIFICATION:
      return {
        ...state,
        searchKycVerificationData: action.payload,
        searchKycVerificationMeta: action.meta,
      };
    case types.SEARCH_PASSWORD_VERIFICATION:
      return {
        ...state,
        searchKycPasswordVerificationData: action.payload,
        searchKycPasswordVerificationMeta: action.meta,
      };
    case types.SEARCH_PIN_VERIFICATION:
      return {
        ...state,
        searchKycPinVerificationData: action.payload,
        searchKycPinVerificationMeta: action.meta,
      };
    // return {
    //   ...state,
    //   searchData0:
    //     action.payload &&
    //     state.kycLevelZero.filter(
    //       (user) =>
    //         (user.name && user.name.toLowerCase().includes(action.payload.toLowerCase())) ||
    //         (user.bvn &&
    //           user.bvn.bvn_number
    //             .toLowerCase()
    //             .includes(action.payload.toLowerCase())) ||
    //         (user.telephone && user.telephone
    //           .toLowerCase()
    //           .includes(action.payload.toLowerCase()))
    //     ),
    // };
    // case types.SEARCH_KYC1:
    // return {
    //   ...state,
    //   searchData:
    //     action.payload &&
    //     state.kycLevelOne.filter(
    //       (user) =>
    //         user.name.toLowerCase().includes(action.payload.toLowerCase()) ||
    //         (user.bvn &&
    //           user.bvn.bvn_number
    //             .toLowerCase()
    //             .includes(action.payload.toLowerCase())) ||
    //         user.telephone
    //           .toLowerCase()
    //           .includes(action.payload.toLowerCase())
    //     ),
    // };
    // case types.SEARCH_KYC2:
    //   return {
    //     ...state,
    //     searchLevel2Data:
    //       action.payload &&
    //       state.kycLevelTwo.filter(
    //         (user) =>
    //           user.name.toLowerCase().includes(action.payload.toLowerCase()) ||
    //           (user.bvn &&
    //             user.bvn.bvn_number
    //               .toLowerCase()
    //               .includes(action.payload.toLowerCase())) ||
    //           user.telephone
    //             .toLowerCase()
    //             .includes(action.payload.toLowerCase())
    //       ),
    //   };
    // case types.SEARCH_ADDR_VERIFICATION:
    // return {
    //   ...state,
    //   searchAddrVerification:
    //     action.payload &&
    //     state.kycVerification.filter(
    //       (user) =>
    //         user.name.toLowerCase().includes(action.payload.toLowerCase()) ||
    //         (user.bvn &&
    //           user.bvn.bvn_number
    //             .toLowerCase()
    //             .includes(action.payload.toLowerCase())) ||
    //         user.telephone
    //           .toLowerCase()
    //           .includes(action.payload.toLowerCase())
    //     ),
    // };
  }
};
export default KYCReduce;
