import { useNavigate } from "react-router-dom";

import "./css/notfoundpage.css";
import ErrorSVG from "./img/error.svg";
import _404SVG from "./img/404.svg";
import Home from "./img/home.svg";
import Logo from "./components/logo";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const Homepage = () => {
    navigate("/");
  };

  const ErrorTop = () => (
    <div className="top-container">
      <div className="top-content">
        <h3 style={{ fontSize: "px" }}>
          <small style={{ fontWeight: "580" }}>Error</small>
          <img src={ErrorSVG} alt="error" width={20} height={15} />
        </h3>
      </div>

      <div className="logo-nav">
        <Logo />
      </div>
    </div>
  );

  return (
    <>
      <div className="notfound-container">
        <ErrorTop />
        <div className="notfound-body">
          <div className="notfound-content">
            <div>
              <h6>
                You seem lost as the page you are looking for does not exist
                Click the button below to return to safety
              </h6>
            </div>
            <div>
              <img src={_404SVG} alt="404_logo" height={200} width={200} />
            </div>
            <div className="notfound-footer">
              <div className="notfound-footer-content" onClick={Homepage}>
                <div>
                  <img src={Home} alt="home_logo" width={17} height={17} />
                  
                </div>
                
                <small style={{fontSize: "13px", fontWeight:"700", marginLeft:"5px"}}>Back to Homepage</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
