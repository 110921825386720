import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import "./dashboard.css";
import TransactionInsight from "../../components/dashboard/transaction-insight";
import CashProvider from "../../components/dashboard/cash-provider";
import IssueComp from "../../components/issues/issues-comp";
import LineChart from "../../components/issues/line-chart";
import KYCDistribution from "../../components/kyc-distr";
// import { Link } from "react-router-dom";
import KYCTOP from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";
import { authActions, dashboardActions } from "../../state/actions";

import "charts.css";
import Spinner from "../login/spinner";
export default function Dashboard() {
  const name = authActions.DecodeToken().name;

  const { data, loading } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  const { LoadDashboard, LoadDashboardAnalytics } = bindActionCreators(
    dashboardActions,
    dispatch
  );
  

  useEffect(() => {
   
    LoadDashboard();
    LoadDashboardAnalytics();

    //eslint-disable-next-line
  }, []);


  const complaint = [
    {
      total: {
        title: "Complaints",
        value: (data && data.complaints && data.complaints.total_count ) || 0,
      },
      done: {
        title: "Resolved",
        value: (data && data.complaints && data.complaints.pending_count ) || 0,
      },
      undone: {
        title: "Pending",
        value:  (data && data.complaints && data.complaints.resoled_count ) || 0,
      },
    },
    {
      total: {
        title: "Total User Count",
        value: data !== null ? data.user_count : 0,
      },
      done: {
        title: "Active users",
        value: data !== null ? data.active_user_count : 0,
      },
      undone: {
        title: "Inactive users",
        value: data !== null ? data.inactive_user_count : 0,
      },
    },
    {
      total: {
        title: "Address Verification",
        value: (data && data.address_verification && data.address_verification.total_count ) || 0,
      },
      done: {
        title: "Approved",
        value: (data && data.address_verification && data.address_verification.approved_count ) || 0,
      },
      undone: {
        title: "Pending",
        value: (data && data.address_verification && data.address_verification.pending_count ) || 0,
      },
    },
    {
      total: {
        title: "KYC vendors",
        value: 0,
      },
      done: {
        title: "Active",
        value: 0,
      },
      undone: {
        title: "Inactive",
        value: 0,
      },
    },
    {
      total: {
        title: "Bill Vendors",
        value: 1,
      },
      done: {
        title: "Active",
        value: 1,
      },
      undone: {
        title: "Inactive",
        value: 0,
      },
    },
  ];

  return (
    <>
      {loading && <div className="dashboard-user-preloader"><Spinner /></div>}
      <div className="dashboard-container">
        <div className="dashboard-nav">
          <Nav Active={1} />
        </div>
        <div className="right-content dashboard-content-wrapper">
          <KYCTOP url={"Welcome, "} suburl={name.split(" ")[1]} />
          <div
            className="dashboard-body-wrapper"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="dashboard-content">
              <div style={{ width: "48%", marginRight: "0px" }}>
                <LineChart data={data} />
              </div>
              <div style={{ width: "50%" }}>
                <TransactionInsight />
              </div>
            </div>
            <div className="kyc-dist-cash-provider">
              <div className="kyc-issue">
                <div>
                  <KYCDistribution />
                </div>

                <IssueComp data={complaint[4]} />
              </div>
              <div style={{ width: "53%" }}>
                <div style={{ marginBottom: "20px" }}>
                  {/* <Link to="/vendor" className="rm-link-dec"> */}
                    <CashProvider />
                  {/* </Link> */}
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "30px",
                  }}
                >
                  <IssueComp data={complaint[1]} />
                  <IssueComp data={complaint[0]} />
                  <IssueComp data={complaint[2]} />
                  <IssueComp data={complaint[3]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
