// import { PieChart } from "react-minimal-pie-chart";
import "../../css/transaction_insight.css";
import { Pie } from "@ant-design/plots";
import { useSelector } from "react-redux";
export default function TransactionInsight() {

  const { data } = useSelector((state) => state.dashboard);
  
  const transaction = (data && data.transaction ) || {}
  const bills = (data && data.bills ) || {}
  const cashRequest = (data && data.cash_request ) || {}
  const formatValue = (value) => {
    return Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }
  return (
    <div className="dashboard-card-color transaction-insight-container">
      <div className="transaction-details-section">
        <div className="transaction-metric-section">
          <h4>Transaction Volume Insight</h4>
          <p>Transaction Volume NGN {(transaction.total_volume && formatValue(transaction.total_volume)) || "0"}</p>
          <p>Total Transaction Count: {transaction.total_count || 0}</p>
          <p>Successful Transaction Count: {transaction.success_count}</p>
          {/* <div className="transaction-select">
            <span>
              <Calender />
            </span>
            <span>
              <p>Select Time Range</p>
            </span>
          </div> */}
        </div>

        <div className="transaction-insight-legends">
          <div className="transaction-insight-legend">
            <span style={{ background: "#05F300" }}></span>
            <p>Cash Request</p>
          </div>
          <div className="transaction-insight-legend">
            <span style={{ background: "#981EF8" }}></span>
            <p>Airtime & Data Bills</p>
          </div>
          <div className="transaction-insight-legend">
            <span style={{ background: "#FF6C02" }}></span>
            <p>TV Subscription</p>
          </div>
          <div className="transaction-insight-legend">
            <span style={{ background: "#1EEBF8" }}></span>
            <p>Other Bills</p>
          </div>
        </div>
      </div>
      <div className="transaction-chart-section">
        <DemoPie cashRequest={cashRequest} bills={bills} />
      </div>
    </div>
  );
}

const DemoPie = ({cashRequest, bills}) => {
  
  const tv = bills.tv_bill_count || 0
  const cashrequest = cashRequest.total_count || 0
  const airtime = bills.airtime_bill_count || 0
  const others = bills.other_bill_count || 0

  const total = Number(tv) + Number(cashrequest) + Number(airtime) + Number(others)
  const map = new Map()
  map.set(Math.round((cashrequest/total) * 100), cashrequest)
  map.set(Math.round((airtime/total) * 100), airtime)
  map.set(Math.round((tv/total) * 100), tv)
  map.set(Math.round((others/total) * 100), others)
  const format = (value) => {
    return map.get(value)
  }
  const data = [
    {
      type: "Cash Request",
      value: Math.round((cashrequest/total) * 100),
    },
    {
      type: "Airtime Data",
      value: Math.round((airtime/total) * 100),
    },
    {
      type: "TV Subscription",
      value: Math.round((tv/total) * 100),
    },
    {
      type: "Other Bills",
      value: Math.round((others/total) * 100),
    },
  ];
  const config = {
    
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.3,
    legend: false,
    opacity: 0.5,
    meta: {
      value: {
        formatter: format,
      },
    },
    color: ({ type }) => {
      if (type === "Cash Request") {
        return "#05F300";
      }
      if (type === "Airtime Data") {
        return "#981EF8";
      }
      if (type === "TV Subscription") {
        return "#FF6C02";
      }
      if (type === "Other Bills") {
        return "#1EEBF8";
      }
    },
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}%",
      style: {
        textAlign: "center",
        fontSize: 30,
        fontWeight: 800,
        color: "#000000",
        opacity: 0.5,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  return <Pie {...config} />;
};

// function Calender() {
//   return (
//     <svg
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M8 2V5"
//         stroke="#162082"
//         stroke-width="2"
//         stroke-miterlimit="10"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       />
//       <path
//         d="M16 2V5"
//         stroke="#162082"
//         stroke-width="2"
//         stroke-miterlimit="10"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       />
//       <path
//         d="M3.5 9.08984H20.5"
//         stroke="#162082"
//         stroke-width="2"
//         stroke-miterlimit="10"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       />
//       <path
//         d="M19.21 15.7703L15.6701 19.3103C15.5301 19.4503 15.4 19.7103 15.37 19.9003L15.18 21.2503C15.11 21.7403 15.45 22.0803 15.94 22.0103L17.29 21.8203C17.48 21.7903 17.75 21.6603 17.88 21.5203L21.4201 17.9803C22.0301 17.3703 22.3201 16.6603 21.4201 15.7603C20.5301 14.8703 19.82 15.1603 19.21 15.7703Z"
//         stroke="#162082"
//         stroke-miterlimit="10"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       />
//       <path
//         d="M18.7002 16.2803C19.0002 17.3603 19.8402 18.2003 20.9202 18.5003"
//         stroke="#162082"
//         stroke-miterlimit="10"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       />
//       <path
//         d="M12 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5V12"
//         stroke="#162082"
//         stroke-width="2"
//         stroke-miterlimit="10"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       />
//       <path
//         d="M11.9955 13.7002H12.0045"
//         stroke="#162082"
//         stroke-width="2"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       />
//       <path
//         d="M8.29431 13.7002H8.30329"
//         stroke="#162082"
//         stroke-width="2"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       />
//       <path
//         d="M8.29431 16.7002H8.30329"
//         stroke="#162082"
//         stroke-width="2"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       />
//     </svg>
//   );
// }
