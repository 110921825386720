import { useEffect, useState } from "react";
import "../css/escalate.css";

import axios from "../axios";
import { useSelector } from "react-redux";

// import UserControlOnboarding from "./user-control/user-modal/onboarding-modal";
export default function Escalate({ onCancel, setToggle, setTicketID }) {
  const changeToggle = (value) => {
    console.log(value);
    onCancel();
    setToggle(value);
  };

  const { internalUser } = useSelector((state) => state.support);

  const [phone, setPhone] = useState("");
  const [confirmPh, setConfirmPh] = useState(false);
  const [error, setError] = useState({});
  const [searchUser, setSearchUser] = useState(null);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [user, setUser] = useState("");
  const [priority, setPriority] = useState("low");

  const [loading, setLoading] = useState(false)

  const onChangePhoneNumber = (e) => {
    setError({});
    setPhone(e.target.value.replace(/[^\d.]/gi, ""));
    confirmPhone(e.target.value.replace(/[^\d.]/gi, ""));
  };

  const confirmPhone = async (phone) => {
    if (phone.length >= 10) {
      const completePhone = Number("234" + Number(phone));
      try {
        const res = await axios.get(`/user-profile?term=${completePhone}`);

        if (res.data.data.users.data.length > 0) {
          setSearchUser(res.data.data.users.data[0]);
          setConfirmPh(true);
        } else {
          console.log("got here");
          setError({ phone: "No record found for user" });
          setConfirmPh(false);
          setSearchUser(null);
        }
        return;
      } catch (err) {
        setError({ phone: "OPPS!!!, Something went wrong" });
        setConfirmPh(false);
        setSearchUser(null);
      }
    } else {
      setSearchUser(null);
    }
  };

  const escalateTo = async () => {
    setLoading(true)
    if (!confirmPh) {
      setError({ phone: "Please provide a valid phone number" });
    } else if (title.trim().length === 0) {
      setError({ title: "This field is required" });
    } else if (description.trim().length === 0) {
      setError({ description: "This field is required" });
    } else if (user.trim().length === 0) {
      setError({ user: "This field is required" });
    } else if (priority.trim().length === 0) {
      setError({ priority: "This field is required" });
    } else {
      setError("");
      const completePhone = Number("234" + Number(phone));
      try {
        const res = await axios.post(`/tickets`, {
          customer_telephone: completePhone,
          internal_user_id: user,
          priority_level: priority,
          title: title,
          description: description,
        });
        // console.log(res.data.data.internal_users)
        setTicketID(res.data.data.ticket.ticket_reference);
        changeToggle(5);
        setLoading(false)
      } catch (err) {
        if (err.response.status === 500 || err.response.status === 404) {
          setError({ phone: err.response.data.error.message });
          setSearchUser(null);
        } else {
          setError({ phone: err.response.data.error.message.join("\n") });
          setSearchUser(null);
        }
        setLoading(false)
      }
    }
    setLoading(false)
  };

  return (
    <>
      <div className="escalate-container">
        <button onClick={onCancel} className="escalate-close">
          <Cancel />
        </button>
        <div style={{ marginBottom: "10px" }}>
          <h6>Customer Phone No</h6>
          <input
            type="text"
            placeholder="Enter customer's phone number"
            value={phone}
            maxLength={11}
            onChange={(e) => onChangePhoneNumber(e)}
          />

          {error.phone && (
            <strong style={{ marginLeft: "15px" }} className="text-danger">
              {error.phone}
            </strong>
          )}
          {searchUser && (
            <strong style={{ marginLeft: "15px" }} className="text-success">
              {searchUser.name}
            </strong>
          )}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <h6>Title</h6>
          <input
            className="escalate-text-input"
            type="text"
            placeholder="Enter Title"
            value={title}
            onChange={(e) => {
              setError("");
              setTitle(e.target.value);
            }}
          />
          {error.title && <span className="text-danger">{error.title}</span>}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <h6>Description</h6>
          <textarea
            className="textarea"
            value={description}
            onChange={(e) => {
              setError("");
              setDescription(e.target.value);
            }}
          ></textarea>
          {error.description && (
            <span className="text-danger">{error.description}</span>
          )}
        </div>

        <div style={{ marginBottom: "17px" }}>
          <h6>Escalate to</h6>
          <div className="escalate-checkbox">
            <select value={user} onChange={(e) => setUser(e.target.value)}>
              <option value="">Select Agent</option>
              {internalUser.map((user) => (
                <option value={user.id}>{user.name}</option>
              ))}
            </select>
            {error.user && <span className="text-danger">{error.user}</span>}
          </div>
        </div>
        <div style={{ marginBottom: "40px" }}>
          <h6>Priority Level</h6>
          <select
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
          >
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
          {error.priority && (
            <span className="text-danger">{error.priority}</span>
          )}
        </div>
        <div style={{ marginBottom: "17px" }}>
          <button className="escalate-btn" onClick={escalateTo} disabled={loading}>
            Escalate
          </button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </>
  );
}

export const UpdateTicket = ({ setToggle, id, setTicketID }) => {
  const changeToggle = (value) => {
    setToggle(value);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`/tickets/${id}`);
        const ticket = res.data.data.ticket;
        setPhone(ticket.customer && ticket.customer.telephone.replace("+234", "0"));
        setConfirmPh(true)
        setDescription(ticket.description);
        setSearchUser(ticket.customer && ticket.customer);
        setUser(ticket.internal_user && ticket.internal_user.id);
        setPriority(ticket.priority_level);
      } catch (err) {}
    })();

    // eslint-disable-next-line
  }, []);

  const { internalUser } = useSelector((state) => state.support);

  const [phone, setPhone] = useState("");
  const [confirmPh, setConfirmPh] = useState(false);
  const [error, setError] = useState({});
  const [searchUser, setSearchUser] = useState(null);

  const [description, setDescription] = useState("");
  const [user, setUser] = useState("");
  const [priority, setPriority] = useState("low");

  const [loading, setLoading] = useState(false)

  const onChangePhoneNumber = (e) => {
    setError({});
    setPhone(e.target.value.replace(/[^\d.]/gi, ""));
    confirmPhone(e.target.value.replace(/[^\d.]/gi, ""));
  };

  const confirmPhone = async (phone) => {
    if (phone.length >= 10) {
      const completePhone = Number("234" + Number(phone));
      try {
        const res = await axios.get(`/user-profile?term=${completePhone}`);

        if (res.data.data.users.data.length > 0) {
          setSearchUser(res.data.data.users.data[0]);
          setConfirmPh(true);
        } else {
          console.log("got here");
          setError({ phone: "No record found for user" });
          setConfirmPh(false);
          setSearchUser(null);
        }
        return;
      } catch (err) {
        setError({ phone: "OPPS!!!, Something went wrong" });
        setConfirmPh(false);
        setSearchUser(null);
      }
    } else {
      setSearchUser(null);
      setConfirmPh(false);
    }
  };

  const escalateTo = async () => {
    setLoading(true)
    if (!confirmPh) {
      setError({ phone: "Please provide a valid phone number" });
    } else if (description.trim().length === 0) {
      setError({ description: "This field is required" });
    } else if (user.trim().length === 0) {
      setError({ user: "This field is required" });
    } else if (priority.trim().length === 0) {
      setError({ priority: "This field is required" });
    } else {
      setError("");
      const completePhone = Number("234" + Number(phone));
      try {
        const res = await axios.patch(`/tickets/${id}`, {
          customer_telephone: completePhone,
          internal_user_id: user,
          priority_level: priority,
          description: description,
          status: "pending"
        });
        // console.log(res.data.data.internal_users)
        setTicketID(res.data.data.ticket.ticket_reference);
        changeToggle(7);
        setLoading(false)
      } catch (err) {
        if (err.response.status === 500 || err.response.status === 404) {
          setError({ phone: err.response.data.error.message });
          setSearchUser(null);
          setLoading(false)
        } else {
          setError({ phone: err.response.data.error.message.join("\n") });
          setSearchUser(null);
          setLoading(false)
        }
      }
    }

    setLoading(false)
  };

  return (
    <>
      <div className="update-escalate-container">
        <div className="escalate-container">
          <button onClick={() => changeToggle(0)} className="escalate-close">
            <Cancel />
          </button>
          <div style={{ marginBottom: "10px" }}>
            <h6>Customer Phone No</h6>
            <input
              type="text"
              placeholder="Enter customer's phone number"
              value={phone}
              maxLength={11}
              onChange={(e) => onChangePhoneNumber(e)}
            />

            {error.phone && (
              <strong style={{ marginLeft: "15px" }} className="text-danger">
                {error.phone}
              </strong>
            )}
            {searchUser && (
              <strong style={{ marginLeft: "15px" }} className="text-success">
                {searchUser.name}
              </strong>
            )}
          </div>
          {/* <div style={{ marginBottom: "10px" }}>
          <h6>Title</h6>
          <input
            className="escalate-text-input"
            type="text"
            placeholder="Enter Title"
            value={title}
            onChange={(e) => {
              setError("");
              setTitle(e.target.value);
            }}
          />
          {error.title && <span className="text-danger">{error.title}</span>}
        </div> */}
          <div style={{ marginBottom: "10px" }}>
            <h6>Description</h6>
            <textarea
              className="textarea"
              value={description}
              onChange={(e) => {
                setError("");
                setDescription(e.target.value);
              }}
            ></textarea>
            {error.description && (
              <span className="text-danger">{error.description}</span>
            )}
          </div>

          <div style={{ marginBottom: "17px" }}>
            <h6>Escalate to</h6>
            <div className="escalate-checkbox">
              <select value={user} onChange={(e) => setUser(e.target.value)}>
                <option value="">Select Agent</option>
                {internalUser.map((user) => (
                  <option value={user.id}>{user.name}</option>
                ))}
              </select>
              {error.user && <span className="text-danger">{error.user}</span>}
            </div>
          </div>
          <div style={{ marginBottom: "40px" }}>
            <h6>Priority Level</h6>
            <select
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
            {error.priority && (
              <span className="text-danger">{error.priority}</span>
            )}
          </div>
          <div style={{ marginBottom: "17px" }}>
            <button className="escalate-btn" onClick={escalateTo} disabled={loading}>
              Update
            </button>
            <button onClick={() => changeToggle(0)}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
};

function Cancel() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.208 0C21.276 0 24 2.856 24 7.104V16.9092C24 21.1452 21.276 24 17.208 24H6.804C2.736 24 0 21.1452 0 16.9092V7.104C0 2.856 2.736 0 6.804 0H17.208ZM15.612 8.3652C15.204 7.956 14.544 7.956 14.124 8.3652L12 10.5L9.864 8.3652C9.444 7.956 8.784 7.956 8.376 8.3652C7.968 8.7732 7.968 9.4452 8.376 9.852L10.512 11.9892L8.376 14.1132C7.968 14.5332 7.968 15.1932 8.376 15.6C8.58 15.804 8.856 15.9132 9.12 15.9132C9.396 15.9132 9.66 15.804 9.864 15.6L12 13.4772L14.136 15.6C14.34 15.8172 14.604 15.9132 14.868 15.9132C15.144 15.9132 15.408 15.804 15.612 15.6C16.02 15.1932 16.02 14.5332 15.612 14.1252L13.476 11.9892L15.612 9.852C16.02 9.4452 16.02 8.7732 15.612 8.3652Z"
        fill="#162082"
      />
    </svg>
  );
}
