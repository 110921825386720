import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import "../../../css/kyc/level1kyc.css";
import KYCTOP from "../../kyc-top/kyc-top";
import Nav from "../../nav";
import Lilmodal from "../../modals/mfCreation";
import MfRed from "../../modals/mfCreationRed";
import SuccessNotify from "../../modals/successNotify";
import Level1RejectModal from "./rejectModal";
import RejectConfirmation from "./rejectConfirmation";
import { useParams } from "react-router-dom";
import { RoleCreationError } from "../../vendorModals/appNotificationModal";
import ImageView from "../../image-comparison/image-view";
// import { useSelector } from "react-redux";
// import { authActions } from "../../../state/actions";

const Level1View = () => {
  const [view, setView] = useState("hidden");
  const [comment, setComment] = useState("");
  // const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifications, setVerifications] = useState([]);
  // const [verification, setVerification] = useState([]);
  const [modal, setModal] = useState(0);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const [image1, setImage1] = useState("");

  const filterIDVerification = () => {
    const verification = verifications.filter(
      (v) => v.verification_type === "bvn-selfie-verification"
    );
    return verification;
  };

  const handleClick = (value) => {
    if (filterIDVerification().length === 0) {
      value = 7;
    }

    setView("");
    setModal(value);
  };
  useEffect(() => {
    async function fetchuser() {
      try {
        const newId = atob(id);
        const res = await axios.get(`/kycs/${newId}?include=verifications,kyc`);
        console.log(res.data.data);
        setData(res.data.data);
        setVerifications(res.data.data.user.verifications);
      } catch (error) {
        console.log(error);
      }
    }
    fetchuser();
  }, [id]);

  // const { CheckPermission } = authActions;

  // const { currentUser, currentUserRole } = useSelector((state) => state.auth);
  // const { roles } = currentUser;
  const ApproveDocs = async () => {
    const newId = filterIDVerification()[0].id;
    setLoading(true);
    try {
      await axios.patch(`/verifications/${newId}`, {
        status: "approved",
      });
      // setError()
      setModal(3);
      setLoading(false);
    } catch (err) {
      // setError(err.response.data.error.message)
      setModal(6);
      setLoading(false);
    }
    
  };

  const RejectDocs = async () => {
    const newId = filterIDVerification()[0].id;

    setLoading(true);
    try {
      await axios.patch(`/verifications/${newId}`, {
        status: "rejected",
        comment,
      });
      // setError()
      setModal(5);
      setLoading(false);
    } catch (err) {
      // setError(err.response.data.error.message)
      setModal(6);
      setLoading(false);
    }
  };

  const showDoubleImage = (image) => {
    setImage1(image);
    if (image.trim() !== "") {
      setView("");

      setModal(14);
    }
  };

  const showModal = () => {
    if (modal === 1) {
      return (
        <Lilmodal
          setView={setView}
          loading={loading}
          setModal={setModal}
          handleSubmit={ApproveDocs}
        />
      );
    } else if (modal === 2) {
      return (
        <MfRed
          setView={setView}
          setModal={setModal}
          handleSubmit={RejectDocs}
          setComment={setComment}
          loading={loading}
        />
      );
    } else if (modal === 3) {
      return <SuccessNotify setView={setView} setModal={setModal} />;
    } else if (modal === 4) {
      return (
        <Level1RejectModal
          setView={setView}
          setModal={setModal}
          setComment={setComment}
        />
      );
    } else if (modal === 5) {
      return <RejectConfirmation setView={setView} setModal={setModal} loading={loading} />;
    } else if (modal === 6) {
      return (
        <RoleCreationError
          notify={`OOPS!!! something went wrong, Please try again`}
          setToggle={setModal}
        />
      );
    } else if (modal === 7) {
      return (
        <RoleCreationError
          notify={`OOPS!!! No document to approve or reject`}
          setToggle={setModal}
        />
      );
    } else if (modal === 14) {
      return <ImageView setToggle={setModal} image={image1} />;
    }
  };
  return (
    <>
      <div className="containDash">
        <div>
          <Nav Active={2} />
        </div>

        <div className=" right-content level1View-kyctop">
          <KYCTOP url="KYC/Level 1" suburl="/Customer's Doc" />
          <div className="level1View-content">
            <div className="levelView-content1">
              <div className="content1-container">
                <div className="content1-body1">
                  <h2>Full Name</h2>
                  <p>{data ? data.user.name : ""}</p>
                </div>
                <div className="content1-body1">
                  <h2>Email Address</h2>
                  <p>{data ? data.user.email : ""}</p>
                </div>
                <div className="content1-body1">
                  <h2>Phone Number</h2>
                  <p>{data ? data.telephone : ""}</p>
                </div>
                <div className="content1-body1">
                  <h2>BVN</h2>
                  <p>
                    {data
                      ? (data.user.bvn && data.user.bvn.bvn_number) || "-"
                      : ""}
                  </p>
                </div>
                <div className="content1-body1">
                  <h2>Residential Address</h2>
                  <p>
                    {data
                      ? (data.user.bvn && data.user.bvn.residential_address) ||
                        "-"
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="levelView-content2">
              <div className="content2-container">
                <div className="content2-body2">
                  <h2>BVN Picture</h2>
                  <div className="content2-image">
                    <img
                      width="174"
                      height="174"
                      alt="bvn"
                      src={data ? data.media.bvn_photo : ""}
                      onClick={() =>
                        showDoubleImage(data ? data.media.bvn_photo : "")
                      }
                    />
                  </div>
                </div>
                <div className="content2-body2">
                  <h2>Selfie Picture</h2>
                  <div className="content2-image">
                    <img
                      width="174"
                      height="174"
                      alt="bvn"
                      src={data ? data.media.selfie_photos.bvn : ""}
                      onClick={() =>
                        showDoubleImage(
                          data ? data.media.selfie_photos.bvn : ""
                        )
                      }
                    />
                  </div>
                </div>
                <div className="content2-footer">
                  {/* {roles &&
                    roles.length > 0 &&
                    CheckPermission(currentUserRole, "can approve kyc") && ( */}
                      <button
                        className="content2-approve"
                        onClick={() => handleClick(1)}
                      >
                        Approve
                      </button>
                    {/* )} */}
                  {/* {
                roles &&
                    roles.length > 0 &&
                    CheckPermission(currentUserRole, "can reject kyc") &&  */}
                      <button
                        className="content2-reject"
                        onClick={() => handleClick(4)}
                      >
                        Reject
                      </button>
                    {/* } */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {modal ? (
          <div className={`content1-modal ${view}`}>{showModal()}</div>
        ) : null}
      </div>
    </>
  );
};

export default Level1View;
