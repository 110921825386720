import axios from "../../axios";
import * as types from "../types";

export const FetchKYCLevelOne = (page) => async (dispatch) => {
  page = page || 1
  dispatch({
    type: types.SET_KYC_LOADING,
  });

  try {
    const res = await axios.get(`/kycs?level=level%20one&include=bvn&page%5Bnumber%5D=${page}`);
    dispatch({
      type: types.FETCH_KYC_LEVEL_ONE,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (error) {
    console.log(error)
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const FetchKYCLevelZero = (page) => async (dispatch) => {
  page = page || 1
  dispatch({
    type: types.SET_KYC_LOADING,
  });
  
  try {
    const res = await axios.get(`/kycs?level=level%20zero&include=bvn&page%5Bnumber%5D=${page}`);
    dispatch({
      type: types.FETCH_KYC_LEVEL_ZERO,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (error) {
    console.log(error)
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const FetchKYCLevelTwo = (page) => async (dispatch) => {
  page = page || 1
  // setLoading()
  try {
    const res = await axios.get(`/kycs/fetchAgentVerification?level=level%20two&filterBy=business address verification&include=bvn&page%5Bnumber%5D=${page}`);
    dispatch({
      type: types.FETCH_KYC_LEVEL_TWO,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (error) {
    console.log(error)
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const FetchAgency = (page) => async (dispatch) => {
  page = page || 1
  // setLoading()
  try {
    const res = await axios.get(`/kycs/fetchAgentVerification?level=level%20two&filterBy=cac%20document%20verification&include=bvn&page%5Bnumber%5D=${page}`);

    dispatch({
      type: types.FETCH_KYC_AGENCY,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (error) {
    console.log(error)
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const FetchVerifications = (page) => async (dispatch) => {
  page = page || 1
  // setLoading()
  try {
    const res = await axios.get(`/kycs?level=verifications&page%5Bnumber%5D=${page}`);
    dispatch({
      type: types.FETCH_KYC_VERIFICATION,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const FetchPasswordVerification = (page) => async (dispatch) => {
  page = page || 1
  // setLoading()
  try {
    const res = await axios.get(`/pin/password?filter%5Btype%5D=reset-password-selfie-verification&page%5Bnumber%5D=${page}`);
    dispatch({
      type: types.FETCH_PASSWORD_VERIFICATION,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (error) {
    console.log(error)
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const FetchPinVerification = (page) => async (dispatch) => {
  page = page || 1
  // setLoading()
  try {
    const res = await axios.get(`/pin/password?filter%5Btype%5D=reset-pin-selfie-verification&page%5Bnumber%5D=${page}&include=verifications`);
    dispatch({
      type: types.FETCH_PIN_VERIFICATION,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (error) {
    console.log(error)
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const SetKycUser = (id) => (dispatch) => {
  dispatch( {
    type: types.SET_USER,
    payload: id,
  })
}

export const searchKycLevelZero = (search, page) => async (dispatch) => {
  // setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/kycs?level=level%20zero&include=bvn&page%5Bnumber%5D=${page}&term=${search.trim()}`
      // "&filter[telephone]=${search}&filter[name]=${search}"
    );
    dispatch({
      type: types.SEARCH_KYC0,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const searchKycPassword = (search, page) => async (dispatch) => {
  // setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/pin/password?filter[type]=reset-password-selfie-verification&page%5Bnumber%5D=${page}&term=${search.trim()}`
    );
    dispatch({
      type: types.SEARCH_PASSWORD_VERIFICATION,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const searchKycPin = (search, page) => async (dispatch) => {
  // setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/pin/password?filter%5Btype%5D=reset-pin-selfie-verification&page%5Bnumber%5D=${page}&term=${search.trim()}`
    );
    dispatch({
      type: types.SEARCH_PIN_VERIFICATION,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const searchKycLevelOne = (search, page) => async (dispatch) => {
  // setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/kycs?level=level%20one&include=bvn&page%5Bnumber%5D=${page}&term=${search.trim()}`
      // "&filter[telephone]=${search}&filter[name]=${search}"
    );
    dispatch({
      type: types.SEARCH_KYC1,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const searchKycAgency = (search, page) => async (dispatch) => {
  // setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/kycs/fetchAgentVerification?level=level%20two&filterBy=cac%20document%20verification&include=bvn&page%5Bnumber%5D=${page}&term=${search.trim()}`
      // "&filter[telephone]=${search}&filter[name]=${search}"
    );
    dispatch({
      type: types.SEARCH_KYC2,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const searchKycVerification = (search, page) => async (dispatch) => {
  // setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/kycs/fetchAgentVerification?level=level%20two&filterBy=business address verification&include=bvn&page%5Bnumber%5D=${page}&term=${search.trim()}`
      // "&filter[bvn.bvn_number]=${search}&filter[name]=${search}"
    );
    dispatch({
      type: types.SEARCH_ADDR_VERIFICATION,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_KYC_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const searchLevelZero = (search) => (dispatch) => {
  search = search.trim() === "" ? null : search.trim();
  console.log(search)
  dispatch({
    type: types.SEARCH_KYC0,
    payload: search,
  });
  
};

export const searchLevelOne = (search) => (dispatch) => {
  search = search.trim() === "" ? null : search.trim();
  dispatch({
    type: types.SEARCH_KYC1,
    payload: search,
  });
};

export const searchLevelTwo = (search) => (dispatch) => {
  search = search.trim() === "" ? null : search.trim();
  dispatch({
    type: types.SEARCH_KYC2,
    payload: search,
  });
};

export const searchAddressVerification = (search) => (dispatch) => {
  search = search.trim() === "" ? null : search.trim();
  dispatch({
    type: types.SEARCH_ADDR_VERIFICATION,
    payload: search,
  });
};

export const ApproveKyc = (id, comment, toggle) => async (dispatch) => {
  setLoading()
  try {
    await axios.patch(`https://api.zojapay.com/api/v1/admin/verifications/${id}`, {
      comment,
      status: "approved"
    })
    dispatch({
      type: types.APPROVE_KYC,
      toggle: toggle
    })
  } catch (err) {
    dispatch({
      type: types.APPROVE_KYC_ERROR,
      payload: { top: err.response.data.error.message },
      toggle: 10,
    });
  }
}

export const RejectKyc = (id, comment, toggle) => async (dispatch) => {
  setLoading()
  try {
    await axios.patch(`https://api.zojapay.com/api/v1/admin/verifications/${id}`, {
      comment,
      status: "rejected"
    })
    dispatch({
      type: types.REJECT_KYC,
      toggle: toggle
    })
  } catch (err) {
    dispatch({
      type: types.APPROVE_KYC_ERROR,
      payload: { top: err.response.data.error.message },
      toggle: 10,
    });
  }
}
export const setLoading = () => (dispatch) => {
  dispatch({
    type: types.SET_KYC_LOADING,
  });
};

export const setActiveCard = (id, url) => (dispatch) => {
  dispatch({
    type: types.SET_CARD_ACTIVE,
    payload: { id, url },
  });
};
