import { useEffect} from "react";

import Card from "../../components/user-control/card";
import KYCTOP from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userControlActions } from "../../state/actions";

export default function UserControl() {
  const dispatch = useDispatch();

  const { LoadRoles, FetchUsers } = bindActionCreators(userControlActions, dispatch);

  const {subUrl} = useSelector((state) => state.userControl)
  useEffect(() => {
    LoadRoles()
    FetchUsers()
    // console.log(loading)
    
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="gen-cont">
        <div>
          <Nav Active={5} />
        </div>
        <div className="right-content">
          <KYCTOP url="User Control" suburl={subUrl} />
          <Card />
        </div>
      </div>
    </>
  );
}
