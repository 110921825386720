import { useSelector } from "react-redux";

import "../css/kyc-distr.css";
// import { Column } from "@ant-design/plots";

import "charts.css";

export default function KYCDistribution() {
  const { data, loading } = useSelector((state) => state.dashboard);

  let max = 1;
  let kycs = [0, 0, 0, 0];
  let level0 = 0;
  let level1 = 0;
  let level2 = 0;
  let agent_level = 0;
  if (data !== null) {
    kycs = new Float64Array([
      data.kyc.level_zero,
      data.kyc.level_one,
      data.kyc.level_two,
      data.kyc.agent_level,
    ]);
    
    kycs.sort((a, b) => b - a)

    level0 = loading ? 0 : data.kyc.level_zero;
    level1 = loading ? 0 : data.kyc.level_one;
    level2 = loading ? 0 : data.kyc.level_two;
    agent_level = loading ? 0 : data.kyc.agent_level;
  }
  max = kycs[0]

  const DemoColumn2 = () => {
    return (
      <>
        <table
          id="kyc-column"
          class="charts-css column show-labels data-spacing-5"
        >
          <tbody>
            <tr>
              <th>No KYC</th>
              <td
                style={{
                  "--size": max === 0 ? 0 : level0 / max,
                }}
              >
                <span class="data"> {level0} </span>
              </td>
            </tr>
            <tr>
              <th>Level 1</th>
              <td
                style={{
                  "--size": max === 0 ? 0 : level1 / max,
                }}
              >
                <span class="data"> {level1} </span>
              </td>
            </tr>
            <tr>
              <th>Level 2</th>
              <td
                style={{
                  "--size": max === 0 ? 0 : level2 / max,
                }}
              >
                <span class="data"> {level2} </span>
              </td>
            </tr>
            <tr>
              <th>Agency</th>
              <td
                style={{
                  "--size":
                  max === 0 ? 0 : agent_level / max,
                }}
              >
                <span class="data"> {agent_level} </span>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div>
      <div className="line-chart-section dashboard-card-color">
        <div className="line-chart-title-section">
          <p className="line-chart-title">KYC Distribution</p>
        </div>
        <div className="kyc-distr-detail line-chart-details">
          <div className="chart-details">
            <p className="line-chart-sub-title">Total Customer</p>
            <h1>{data !== null ? level0+level1+level2+agent_level : 0}</h1>
          </div>
          <div>
            <p className="line-chart-sub-title">Pending KYC</p>
            <h1>{data !== null ? data.kyc.pending_kyc_count : 0}</h1>
          </div>
        </div>
        <div className="demo-column">
          <DemoColumn2 />
        </div>
      </div>
    </div>
  );
}

// const DemoColumn = () => {
//   const data = [
//     {
//       type: "No KYC",
//       sales: 38,
//     },
//     {
//       type: "Level 1",
//       sales: 52,
//     },
//     {
//       type: "Level 2",
//       sales: 61,
//     },
//     {
//       type: "Agency",
//       sales: 145,
//     },
//   ];
//   const config = {
//     data,
//     xField: "type",
//     yField: "sales",
//     seriesfield: '',
//     color: '#162082',
//     label: {
//       position: "top",
//       // 'top', 'bottom', 'middle',
//       // 配置样式
//       style: {
//         fill: "#FFFFFF",
//         opacity: 0.6,
//       },
//     },
//     xAxis: {
//       label: {
//         autoHide: true,
//         autoRotate: false,
//       },
//     },
//     meta: {
//       type: {
//         alias: "measure",
//       },
//       sales: {
//         alias: "measure",
//       },
//     },
//   };
//   return <Column {...config} />;
// };
