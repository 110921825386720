import React, { useState, useEffect } from "react";

import "./css/faq.css";
import KYCTOP from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";
// import downArrow from "../../img/downArrow.svg";
// import { IoImageOutline } from "react-icons/io5";
import axios from "../../axios";

import AppNotificationModal, {
  RoleCreationError,
} from "../../components/vendorModals/appNotificationModal";
import { useNavigate, useParams } from "react-router-dom";

function ViewFrequentlyAskedQuestion() {
  // const file = useRef();

  const [error, setError] = useState({});

  // const [selectedFile, setSelectedFile] = useState(null);
  // const [fileName, setFileName] = useState("");
  const [toggle, setToggle] = useState(0);
  const [loading, setLoading] = useState(false);
  // const activePlatform = ["google", "mailChimp", "border"];
  const activePlatform = ["App", "Web", "Both"];
  const { id } = useParams();

  const [data, setData] = useState({
    question: "",
    propose_solution: "",
    active_platform: activePlatform[0],
    status: "active",
    _method: "patch",
  });

  // const Add = activePlatform.map((Add) => Add);
  const handleSelect = (e) => {
    setData((newdata) => ({ ...newdata, active_platform: e.target.value }));
    console.log(data);
    console.log(e.target.value);
  };

  useEffect(() => {
    async function fetchuser() {
      try {
        // const newId = atob(id);
        const res = await axios.get(`/faq/${atob(id)}`);
        // console.log(res.data.data.faq, "here");
        setData({
          ...data,
          question: res.data.data.faq.question,
          propose_solution: res.data.data.faq.propose_solution,
        });
        // setFileName((c) => (c = res.data.data.faq.image_url));
      } catch (err) {
        console.log(err);
      }
    }
    fetchuser();

    //eslint-disable-next-line
  }, [id]);

  function handleChange(e) {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // console.log(data);
  }
  const navigate = useNavigate();
  // const onUpload = () => {
  //   file.current.click();
  // };

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    if (data.question.trim() === "") {
      setError({ question: "This field is required" });
      setLoading(false)
    } else if (data.propose_solution.trim() === "") {
      setError({ propose_solution: "This field is required" });
      setLoading(false)
    } else {
      let formdata = new FormData();
      // formdata.append("image", selectedFile);
      for (const property in data) {
        formdata.append(`${property}`, data[property]);
      }
      axios
        .post(`/faq/${atob(id)}`, formdata, {
          header: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          // console.log(res);
          setToggle(1);
          setLoading(false)
        })
        .catch((err) => {
          if (err.response.status === 500 || err.response.status === 404) {
            setError({ top: err.response.data.error.message });
          } else {
            setError({ top: err.response.data.error.message.join("\n") });
          }
          setLoading(false)
          setToggle(2);
        });
    }
  };

  // const onFileUpload = (e) => {
  //   setSelectedFile(e.target.files[0]);
  //   if (e.target.files[0]) {
  //     setFileName(e.target.files[0].name);
  //   }
  //   console.log(selectedFile);
  // };

  const handleToggle = () => {
    if (toggle === 0) {
      return null;
    }
    if (toggle === 1) {
      return (
        <div className="close-deactivate">
          <AppNotificationModal
            notify={"You have successfully updated this Faq Notification"}
            setToggle={setToggle}
          />
        </div>
      );
    }
    if (toggle === 2) {
      return (
        <div className="close-deactivate">
          <RoleCreationError
            notify={`OOPS! Something went wrong, Please try again"`}
            setToggle={setToggle}
            close="close"
          />
        </div>
      );
    }
  };

  return (
    <>
      <div className="faq-section">
        {!data && <div className="user-preloader"></div>}
        <div className="faq-app-nav">
          <Nav Active={6} />
        </div>
        <div className="right-content faq-notification-wrapper">
          <div className="faq-app-top">
            <KYCTOP url="App Contents" suburl="/View FAQ" />{" "}
          </div>

          <form onSubmit={handleSubmit} className="faq-container">
            <div className="faq-header">
              <h2>View Item Details</h2>
            </div>
            {error.top && <span className="text-danger">{error.top}</span>}

            <div className="faq-title">
              <h4>Frequently Asked Question</h4>
              <input
                value={data.question}
                type="text"
                name="question"
                id="name"
                placeholder="Enter question"
                onChange={handleChange}
              />
            </div>
            {error.question && (
              <span className="text-danger">{error.question}</span>
            )}

            <div className="faq-message">
              <h4>Prosposed Solution</h4>
              <textarea
                name="propose_solution"
                value={data.propose_solution}
                id=""
                cols="30"
                rows="15"
                placeholder="Enter answer to the FAQ"
                onChange={handleChange}
              ></textarea>
            </div>
            {error.propose_solution && (
              <span className="text-danger">{error.propose_solution}</span>
            )}

            {/* <div className="new-article-content-1">
              <div className="article-cont-1">
                <h5>Attach Item Image</h5>
                <div className="article-cont-1-input" onClick={onUpload}>
                  <IoImageOutline size={20} />
                  <div className="article-input-placeholder">
                    {fileName === "" ? (
                      "Click here to attach image"
                    ) : (
                      <div style={{ width: "250px" }}>
                        {fileName.substring(0, 25)}
                      </div>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={file}
                    style={{ display: "none" }}
                    onChange={onFileUpload}
                  ></input>
                </div>
              </div>
            </div> */}

            <div className="faq-content-2">
              <div>
                <h5>Active Platform</h5>
                <select
                  onChange={handleSelect}
                  className="article-cont-3"
                  name="active_platform"
                  value={data.active_platform}
                >
                  <option value="App">App</option>
                  <option value="Web">Web</option>
                  <option value="Both">Both</option>
                  {/* {Add.map((platform, key) => (
                    <option value={platform}>{platform}</option>
                  ))} */}
                </select>
              </div>
            </div>

            <div className="new-article-footer-3">
              <button type="submit" className="new-article-footer-botton" disabled={loading}>
                Update Item
              </button>
              <button
                type="reset"
                onClick={() => navigate("/app-content")}
                className="new-article-footer-close"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>

        {handleToggle()}
      </div>
    </>
  );
}

export default ViewFrequentlyAskedQuestion;
