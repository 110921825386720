import React, { useRef, useState } from "react";
import axios from "../../axios";
import "./css/new-article.css";
import KYCTOP from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";
import { IoImageOutline } from "react-icons/io5";
import AppNotificationModal, { RoleCreationError } from "../../components/vendorModals/appNotificationModal";
import { useNavigate } from "react-router-dom";

function NewArticle() {
  const file = useRef();

  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)

  const [files, setFiles] = useState(null)
  const [fields, setFields] = useState({
    title: "",
    content: "",
    active_platform: "App",
    status: "active"
  });

  function handleChange(e) {
    const {name, value} = e.target
    setFields (prev => ({
        ...prev,
        [name]:value
    }))
  }
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [toggle, setToggle] = useState(0);

  const navigate = useNavigate();
  const onUpload = () => {
    file.current.click();
  };

  const onFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
      setFiles(e.target.files[0])
    }
    console.log(selectedFile);
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    if (fields.title.trim() === "") {
      setError({ title: "This field is required" });
    } else if (fields.content.trim() === "") {
      setError({ content: "This field is required" });
    }else {
      let formdata = new FormData();
      formdata.append("image", files);
      for (const property in fields) {
        formdata.append(`${property}`,fields[property])
      }
      await axios.post("/articles",formdata,{
        header: {
            "Content-Type": "multipart/form-data",
        }
      }).then((res) =>{
        
        setToggle(1)
      }).catch((err) =>{
        if (err.response.status === 500 || err.response.status === 404) {
          setError({top: err.response.data.error.message});
        } else {
          setError({top: err.response.data.error.message.join("\n")});
        }
        setToggle(2)
      });
    }
    setLoading(false)
  }

  const handleToggle = () => {
    if (toggle === 0) {
      return null;
    }

    if (toggle === 1) {
      return (
        <div className="close-deactivate">
          <AppNotificationModal
            notify={"You have successfully created a new Article Notification"}
            setToggle={setToggle}
          />
        </div>
      );
    }
    if (toggle === 2) {
      return (
        <div className="close-deactivate">
          <RoleCreationError
            notify={`OOPS! Something went wrong, Please try again"`}
            setToggle={setToggle}
            close="close"
          />
        </div>
      );
    }
  };

  return (
    <div className="article-notification">
      <div className="article-app-nav">
        <Nav Active={6} />
      </div>
      <div className="right-content articles-notification-wrapper">
        <div className="article-app-top">
          <KYCTOP url="App Contents" suburl="/Article Notifications" />{" "}
        </div>

        <form onSubmit={handleSubmit} className="article-container">
          <div className="new-article-header">
            <h2>New Item</h2>
          </div>
          {error.top && <span className="text-danger">{error.top}</span>}

          <div className="new-article-title">
            <h5>Item Title</h5>
            <input
              type="text"
              name="title"
              id="name"
              value={fields.title}
              onChange={handleChange}
              placeholder="Enter Article name"
            />
          </div>
          {error.title && <span className="text-danger">{error.title}</span>}

          <div className="new-article-message">
            <h4>Article Content</h4>
            <textarea
              name="content"
              id=""
              cols="30"
              rows="15"
              value={fields.content}
              onChange={handleChange}
              placeholder="Enter a message body"
            ></textarea>
          </div>
          {error.content && <span className="text-danger">{error.content}</span>}

          <div className="new-article-content-1">
            <div className="article-cont-1">
              <h5>Attach Item Image</h5>
              <div className="article-cont-1-input" onClick={onUpload}>
                <IoImageOutline size={20} />
                <div className="article-input-placeholder">
                  {fileName === "" ? (
                    "Click here to attach image"
                  ) : (
                    <div style={{ width: "250px" }}>{fileName}</div>
                  )}
                </div>
                <input
                  type="file"
                  ref={file}
                  style={{ display: "none" }}
                  onChange={onFileUpload}
                ></input>
              </div>
            </div>
          </div>

          {/* <div className="new-article-content-2">
            <div>
              <h5>Active Platform</h5>
              <select className="article-cont-3" name="active_platform" onChange={handleChange} value={fields.active_platform}>
                <option value="App">App</option>
                <option value="Web">Web</option>
              </select>
            </div>
          </div> */}

          <div className="new-article-footer-3">
            <button
              className="new-article-footer-botton" disabled={loading}
            >
              Create Item
            </button>
            <button
              onClick={() => navigate("/app-content")}
              className="new-article-footer-close"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>

      {handleToggle()}
    </div>
  );
}

export default NewArticle;
