import axios from "../../axios";
import * as types from "../types";

const parseError = (err) => {
  if (err.response.status === 500 || err.response.status === 404) {
    return err.response.data.error.message;
  } else {
    return err.response.data.error.message.join("\n");
  }
};

export const LoadRoles = () => async (dispatch) => {
  setloading();
  try {
    const res = await axios.get("/roles?include=permissions");
    dispatch({
      type: types.FETCH_ROLES,
      payload: res.data.data.roles.data,
      meta: res.data.data.roles.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_ROLES_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const FetchUsers = (page) => async (dispatch) => {
  setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/users?page%5Bnumber%5D=${page}&include=roles`
    );
    dispatch({
      type: types.FETCH_USERS,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_USERS_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const FetchUserDetail = (id) => async (dispatch) => {
  setloading();
  try {
    const res = await axios.get(`/users/${id}?include=roles`);
    dispatch({
      type: types.FETCH_USER,
      payload: res.data.data.user,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_USER_ERROR,
      payload: { editTop: err.response.data.error.message },
    });
  }
};

export const GetLoginHistory = (page, id) => async (dispatch) => {
  setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/users/${id}/login-history?page%5Bnumber%5D=${page}`
    );
    dispatch({
      type: types.FETCH_LOGIN_HISTORY,
      payload: res.data.data.users,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_LOGIN_HISTORY_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const DeactivateUser = (id, comment, toggle) => async (dispatch) => {
  setloading();
  try {
    await axios.patch(`/users/${id}/status`, {
      status: "inactive",
      comment,
    });
    dispatch({
      type: types.DEACTIVATE_USER,
      toggle: toggle,
    });
  } catch (err) {
    dispatch({
      type: types.DEACTIVATE_USER_ERROR,
      payload: { active: parseError(err) },
    });
  }
};

export const ReactivateUser = (id, comment, toggle) => async (dispatch) => {
  setloading();
  try {
    await axios.patch(`/users/${id}/status`, {
      status: "active",
      comment,
    });
    dispatch({
      type: types.REACTIVATE_USER,
      toggle: toggle,
    });
  } catch (err) {
    dispatch({
      type: types.REACTIVATE_USER_ERROR,
      payload: { active: parseError(err) },
    });
  }
};
export const CreateUser =
  (email, firstname, lastname, role, value) => async (dispatch) => {
    dispatch({
      type: types.SET_USER_LOADING,
    });
    
    try {
      const res = await axios.post("/users", {
        email,
        first_name: firstname,
        last_name: lastname,
        role,
      });
      dispatch({
        type: types.CREATE_USER,
        payload: res.data.data.user,
        value: value,
      });
    } catch (err) {
      dispatch({
        type: types.CREATE_USER_ERROR,
        payload: { top: parseError(err) },
      });
    }
  };

export const CreateRole = (role, permissions, value) => async (dispatch) => {
  dispatch({
    type: types.SET_ROLE_LOADING,
  });

  try {
    const res = await axios.post("/roles", {
      role,
      permissions,
    });
    dispatch({
      type: types.CREATE_ROLE,
      payload: res.data.data.role,
      value: value,
    });
  } catch (err) {
    dispatch({
      type: types.CREATE_ROLE_ERROR,
      payload: { roleError: parseError(err) },
      value: 2,
    });
  }
};

export const UpdateRole = (roleID, role, permissions, value) => async (dispatch) => {
  dispatch({
    type: types.SET_ROLE_LOADING,
  });
  try {
    const res = await axios.patch(`/roles/${roleID}`, {
      role,
      permissions,
    });
    dispatch({
      type: types.UPDATE_ROLE,
      payload: res.data.data.role,
      value: value,
    });
  } catch (err) {
    dispatch({
      type: types.CREATE_ROLE_ERROR,
      payload: { roleError: parseError(err) },
      value: 2,
    });
  }
};

export const DeleteRole = (roleID, value) => async (dispatch) => {
  dispatch({
    type: types.SET_ROLE_LOADING,
  });

  try {
    await axios.delete(`/roles/${roleID}`);
    dispatch({
      type: types.DELETE_ROLE,
      toggle: value,
      payload: roleID,
    });
  } catch (err) {
    dispatch({
      type: types.DELETE_ROLE_ERROR,
      payload: { roleErr: parseError(err) },
    });
  }
};

export const UpdateUser =
  (id, email, firstname, lastname, role, value) => async (dispatch) => {
    dispatch({
      type: types.SET_USER_LOADING,
    });

    try {
      const res = await axios.patch(`/users/${id}`, {
        email,
        first_name: firstname,
        last_name: lastname,
        role,
      });
      dispatch({
        type: types.UPDATE_USER,
        payload: res.data.data.user,
        value: value,
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_USER_ERROR,
        payload: { editTop: parseError(err) },
      });
    }
  };

export const searchUsers = (search) => (dispatch) => {
  search = search.trim() === "" ? null : search.trim();
  dispatch({
    type: types.SEARCH_USER,
    payload: search,
  });
};
export const searchRoles = (search) => (dispatch) => {
  search = search.trim() === "" ? null : search.trim();
  dispatch({
    type: types.SEARCH_ROLE,
    payload: search,
  });
};

export const setloading = () => (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
  });
};

export const setUserLoading = () => (dispatch) => {
  dispatch({
    type: types.SET_USER_LOADING,
  });
};

export const setActiveCard = (id, name) => (dispatch) => {
  dispatch({
    type: types.SET_ACTIVE_CARD,
    payload: { id, name },
  });
};

export const setError = (error) => (dispatch) => {
  dispatch({
    type: types.USER_ERROR,
    payload: error,
  });
};

export const setUserControlToggle = (value, id) => (dispatch) => {
  dispatch({
    type: types.SET_TOGGLE,
    payload: value,
    id: id,
  });
};

export const setRoleToggle = (value, id) => (dispatch) => {
  dispatch({
    type: types.SET_ROLE_ID,
    payload: value,
    id: id,
  });
};
