import React, { useEffect, useRef, useState } from "react";

import "./css/new-email-notification.css";
import KYCTOP from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";
import { IoImageOutline } from "react-icons/io5";
import AppNotificationModal, {
  RoleCreationError,
} from "../../components/vendorModals/appNotificationModal";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axios";

function ViewArticle() {
  const file = useRef();

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [toggle, setToggle] = useState(0);
  const { id } = useParams();
  // const activePlatform = ["google","mailChimp","border"];
  const activePlatform = ["App", "Web"];
  const [data, setData] = useState({
    title: "",
    content: "",
    active_platform: activePlatform[0],
    status: "active",
    _method: "patch",
  });

  // const Add = activePlatform.map((Add) => Add);
  // const handleSelect = (e) => {
  //   setData((newdata) => ({ ...newdata, active_platform: e.target.value }));
  //   console.log(data);
  //   console.log(e.target.value);
  // };

  useEffect(() => {
    async function fetchuser() {
      try {
        // const newId = atob(id);
        const res = await axios.get(`/articles/${atob(id)}`);
        // console.log(res.data.data.article, "here");
        setData({
          ...data,
          title: res.data.data.article.title,
          content: res.data.data.article.content,
          active_platform: res.data.data.article.active_platform,
        });
        // setFileName((c) => (c = res.data.data.article.image_url));
      } catch (err) {
        console.log(err);
      }
    }
    fetchuser();

    //eslint-disable-next-line
  }, [id]);

  function handleChange(e) {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // console.log(data)
  }
  const navigate = useNavigate();
  const onUpload = () => {
    file.current.click();
  };

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    if (data.title.trim() === "") {
      setError({ title: "This field is required" });
    } else if (data.content.trim() === "") {
      setError({ content: "This field is required" });
    } else {
      let formdata = new FormData();
      formdata.append("image", selectedFile);
      for (const property in data) {
        formdata.append(`${property}`, data[property]);
      }
      axios
        .post(`/articles/${atob(id)}`, formdata, {
          header: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setToggle(1);
        })
        .catch((err) => {
          if (err.response.status === 500 || err.response.status === 404) {
            setError({ top: err.response.data.error.message });
          } else {
            setError({ top: err.response.data.error.message.join("\n") });
          }
          setToggle(2);
        });
    }
    setLoading(false)
  };

  const onFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
    }
    console.log(selectedFile);
  };

  const handleToggle = () => {
    if (toggle === 0) {
      return null;
    }
    if (toggle === 1) {
      return (
        <div className="close-deactivate">
          <AppNotificationModal
            notify={"You have successfully updated an Article Notification"}
            setToggle={setToggle}
          />
        </div>
      );
    }
    if (toggle === 2) {
      return (
        <div className="close-deactivate">
          <RoleCreationError
            notify={`OOPS! Something went wrong, Please try again"`}
            setToggle={setToggle}
            close="close"
          />
        </div>
      );
    }
  };

  return (
    <div className="article-notification">
      {!data && <div className="user-preloader"></div>}
      <div className="article-app-nav">
        <Nav Active={6} />
      </div>
      <div className="right-content articles-notification-wrapper">
        <div className="article-app-top">
          <KYCTOP url="App Contents" suburl="/View Article Notifications" />{" "}
        </div>

        <form onSubmit={handleSubmit} className="article-container">
          <div className="new-article-header">
            <h2>View Item Details</h2>
          </div>
          {error.top && <span className="text-danger">{error.top}</span>}

          <div className="new-article-title">
            <h4>Item Title</h4>
            <input
              type="text"
              value={data.title}
              name="title"
              placeholder="Enter Article name"
              onChange={handleChange}
            />
          </div>
          {error.title && <span className="text-danger">{error.title}</span>}

          <div className="new-article-message">
            <h4>Article Content</h4>
            <textarea
              name="content"
              onChange={handleChange}
              value={data.content}
              cols="30"
              rows="15"
              placeholder="Enter a message body"
            ></textarea>
          </div>
          {error.content && (
            <span className="text-danger">{error.content}</span>
          )}

          <div className="new-article-content-1">
            <div className="article-cont-1">
              <h5>Attach Item Image</h5>
              <div className="article-cont-1-input" onClick={onUpload}>
                <IoImageOutline size={20} /> <p></p>
                <div className="article-input-placeholder">
                  {fileName === "" ? (
                    "Click here to attach image"
                  ) : (
                    <div style={{ width: "250px" }}>
                      {fileName.substring(0, 25)}
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  ref={file}
                  style={{ display: "none" }}
                  onChange={onFileUpload}
                ></input>
              </div>
            </div>
          </div>

          {/* <div className="new-article-content-2">
            <div>
              <h5>Active Platform</h5>
              <select
                onChange={handleSelect}
                className="article-cont-3"
                name="active_platform"
                value={data.active_platform}
              >
                <option value="App">App</option>
                <option value="Web">Web</option>
                
              </select>
            </div>
          </div> */}

          <div className="new-article-footer-3">
            <button type="submit" className="new-article-footer-botton" disabled={loading}>
              Update Item
            </button>
            <button
              type="reset"
              onClick={() => navigate("/app-content")}
              className="new-article-footer-close"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>

      {handleToggle()}
    </div>
  );
}

export default ViewArticle;
