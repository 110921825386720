import "../css/dcmodal.css"
import closeSquare from "../img/Close-Square.svg"
// import docimg from "../img/customer-doc-img.jpeg"
// import Signimg from "../img/sign-img.png"
import { useState } from "react"
import { useSelector } from "react-redux"
import Moment from "react-moment"
import ImageView from "../components/image-comparison/image-view"

const IDCard = "identity-card-verification"
const AgentDocument = "business address verification"
const  BvnSelfiePhoto = "bvn-selfie-verification"

export default function DCModal({ setToggle }) {
    const [close, setClose] = useState(false)
    const [image1, setImage1] = useState("")
    const [modal, setModal] = useState(0);
    const [view, setView] = useState("hidden");

    const handleClose = () => {
        setToggle(0)
        setClose(true)
    }

    const {documentHistory} = useSelector((state) => state.support)
    const {user, media} = documentHistory

    const getIDVerification = () => {
        const verification = user.verifications.filter(
            (v) => v.verification_type === IDCard
          );
          return verification;
    }

    const getSelfiePhotoVerification = () => {
        const verification = user.verifications.filter(
            (v) => v.verification_type === BvnSelfiePhoto
          );
          return verification;
    }
    
    const getAgentVerification = () => {
        const verification = user.verifications.filter(
            (v) => v.verification_type === AgentDocument
          );
          return verification;
    }

    const showDoubleImage = (image) => {
        setImage1(image)
        if (image.trim() !== "") {
          setView("")
          
          setModal(14)
        }
        
      }
      const showModal = ()=>{
        if (modal === 14) {
            return (
              <ImageView
                setToggle={setModal}
                image={image1}
              />
            ); 
          }
      }
    return (
        <>
            {close ? null : (
                <div className="dcmodal-container">
                    <div className="dcmodal-cont">
                        <div className="dcmodal">
                            <img onClick={handleClose} src={closeSquare} alt="close" />
                            <p className="dcmdal-header">Document History</p>
                            <div className="dcmodalbox">
                                <p>Passport</p>
                                <div className="leftbar">
                                    <img onClick={() => showDoubleImage((media &&  ((media.profile_photo) || (media.selfie_photos && media.selfie_photos.bvn))) || "")} className="limg" width="150" height="150" src={(media && ( (media.profile_photo) || (media.selfie_photos && media.selfie_photos.bvn))) || ""} alt="Passport" />
                                    <div className="disturb">
                                        <div className="dblock">
                                            <h5>Status</h5>
                                            <h6>{(getSelfiePhotoVerification() && getSelfiePhotoVerification().length > 0 && getSelfiePhotoVerification()[0].status) || "N/A"}</h6>
                                        </div>
                                        <div className="dblock">
                                            <h5>1st Level Review</h5>
                                            <h6>{(media && media.profile_photo && "N/A") || "N/A"}</h6>
                                        </div>
                                        <div className="dblock">
                                            <h5>Document Source</h5>
                                            <h6>{(getSelfiePhotoVerification() && getSelfiePhotoVerification().length > 0 && getSelfiePhotoVerification()[0].source) || "-"}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="rightbar rp">
                                    <div className="dblock">
                                        <h5>Upload Date</h5>
                                        <h6>{(getSelfiePhotoVerification() && getSelfiePhotoVerification().length > 0 && (
                                            <Moment format="DD/MM/YYYY - hh:mma">
                                            {getSelfiePhotoVerification()[0].created_at}
                                          </Moment>)) || "N/A"}</h6>
                                    </div>
                                    {/* <div className="dblock">
                                        <h5>Upload Portal</h5>
                                        <h6>{(media && media.profile_photo && "-") || "N/A"}</h6>
                                    </div> */}
                                    {/* <div className="dblock">
                                        <h5>ID Card No.</h5>
                                        <h6>N/A</h6>
                                    </div> */}
                                    <div className="dblock">
                                        <h5>2nd Level Review</h5>
                                        <h6>{((media && media.profile_photo) && "-") || "-"}</h6>
                                    </div>
                                    <h5>Rejection Reason</h5>
                                    <h6>{(getSelfiePhotoVerification() && getSelfiePhotoVerification().length > 0 && getSelfiePhotoVerification()[0].comment) || "-"}</h6>

                                </div>
                            </div>

                            <div className="dcmodalbox">
                                <p>ID Card</p>
                                <div className="leftbar">
                                    <img onClick={() => showDoubleImage((media && media.identity_card) || "")} className="limg" width="150" height="150" src={(media && media.identity_card) || ""} alt="ID Card" />
                                    <div className="disturb">
                                        <div className="dblock">
                                            <h5>Status</h5>
                                            <h6>{(getIDVerification().length > 0 && getIDVerification()[0].status) || "N/A"}</h6>
                                        </div>
                                        <div className="dblock">
                                            <h5>1st Level Review</h5>
                                            <h6>{(media && media.identity_card && "N/A") || "N/A"}</h6>
                                        </div>
                                        <div className="dblock">
                                            <h5>Document Source</h5>
                                            <h6>{(getIDVerification().length > 0 && getIDVerification()[0].source) || "N/A"}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="rightbar rp">
                                    <div className="dblock">
                                        <h5>Upload Date</h5>
                                        <h6>{(getIDVerification().length > 0 && (
                                            <Moment format="DD/MM/YYYY - hh:mma">
                                            {getIDVerification()[0].created_at}
                                          </Moment>)) || "-"}</h6>
                                    </div>
                                    {/* <div className="dblock">
                                        <h5>Upload Portal</h5>
                                        <h6>{(media && media.identity_card && "-") || "N/A"}</h6>
                                    </div> */}
                                    <div className="dblock">
                                        <h5>ID Card No.</h5>
                                        <h6>{(media && media.identity_card && getIDVerification()[0].identity_card_number) || "N/A"}</h6>
                                    </div>
                                    <div className="dblock">
                                        <h5>2nd Level Review</h5>
                                        <h6>{(media && media.identity_card && "N/A") || "N/A"}</h6>
                                    </div>
                                    <h5>Rejection Reason</h5>
                                    <h6>{(media && media.identity_card && getIDVerification()[0].comment) || "-"}</h6>

                                </div>
                            </div>

                            <div className="dcmodalbox">
                                <p>Agency Doc</p>
                                <div className="leftbar">
                                    <img onClick={() => showDoubleImage((media && media.cac_document) || "")} src={(media && media.cac_document) || ""} alt="" width="150" height="150" />
                                    <div className="disturb">
                                        <div className="dblock">
                                            <h5>Status</h5>
                                            <h6>{(getAgentVerification() && getAgentVerification().length > 0 &&  getAgentVerification()[0].status) || "-"}</h6>
                                        </div>
                                        <div className="dblock">
                                            <h5>1st Level Review</h5>
                                            <h6>{(media && media.dojah_id_card && "Customer Upload") || "N/A"}</h6>
                                        </div>
                                        <div className="dblock">
                                            <h5>Document Source</h5>
                                            <h6>{(getAgentVerification() && getAgentVerification().length > 0 &&  getAgentVerification()[0].source) || "-"}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="rightbar rp">
                                    <div className="dblock">
                                        <h5>Upload Date</h5>
                                        <h6>{(getAgentVerification() && getAgentVerification().length > 0 && (
                                            <Moment format="DD/MM/YYYY - hh:mma">
                                            {getAgentVerification()[0].created_at}
                                          </Moment>)) || "N/A"}</h6>
                                    </div>
                                    {/* <div className="dblock">
                                        <h5>Upload Portal</h5>
                                        <h6>{(getAgentVerification() && getAgentVerification().length > 0 && "-") || "-"}</h6>
                                    </div> */}

                                    {/* <div className="dblock">
                                        <h5>Reg No.</h5>
                                        <h6>{(getAgentVerification() && getAgentVerification().length > 0 && getAgentVerification()[0].business_registration_number) || "-"}</h6>
                                    </div> */}
                                    
                                    <div className="dblock">
                                        <h5>2nd Level Review</h5>
                                        <h6>{(media && media.dojah_id_card && "N/A") || "N/A"}</h6>
                                    </div>
                                    <h5>Rejection Reason</h5>
                                    <h6>{(getAgentVerification() && getAgentVerification().length > 0 && getAgentVerification()[0].comment) || "-"}</h6>

                                </div>
                            </div>

                        </div>
                    </div>
                    {modal ? (
                        <div className={`content1-modal ${view}`}>{showModal()}</div>
                        ) : null}
                    </div>

            )}

        </>
    )
}
