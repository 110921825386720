import { useState } from "react";
import "../../css/user-control/card.css";
import Kycimg from "../kyc/kyc-img/kyc-img";
import Users from "./users";
import RolesPermission from "./roles-permission";
import VendorProfileDeactivation from "../vendorModals/vendorProfileDeactivation";
import VendorProfileReactivation from "../vendorModals/vendorProfileReactivation";
import VendorSuccessNotify from "../vendorModals/vendorSuccessNotification";
import UserControlLoginHistory from "./user-modal/login-history";
import UserControlDeleteModal from "./user-modal/role-delete-modal";
import AddNewUser from "../modals/user-control-add-new-user";
import UserControlOnboarding from "./user-modal/onboarding-modal";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userControlActions } from "../../state/actions";
import EditUser from "../modals/user-control-edit-user";
import { RoleCreationError } from "../vendorModals/appNotificationModal";
import Spinner from "../../pages/login/spinner";

export default function SettlementCard() {
  const [deactivate, setDeactivate] = useState(false);
  const [reject, setReject] = useState(false);

  const { toggle, roleLoading, usersMeta, rolesMeta, activeCard, cardSize } =
    useSelector((state) => state.userControl);
  const dispatch = useDispatch();

  const { setUserControlToggle, setActiveCard } = bindActionCreators(
    userControlActions,
    dispatch
  );

  const prps = [
    {
      id: "1",
      name: "Users",
      num: usersMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: "2",
      name: "Roles & Permission",
      num: rolesMeta.total || 0,
      numText: "Total Records",
    },
  ];
  function handleActive(id, name) {
    setActiveCard(id, "/" + name);
  }

  const handleReject = () => {
    setReject((c) => (c = true));
    setDeactivate((c) => (c = false));
  };

  // const DeleteRole = () => {
  //   setUserControlToggle()
  // }

  const handleToggle = () => {
    if (toggle === 1) {
      return (
        <div className="user-ven-detail">
          <EditUser />
        </div>
      );
    }
    if (toggle === 2) {
      return (
        <div className="user-ven-detail">
          <VendorProfileDeactivation
            value={5}
            setToggle={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 3) {
      return (
        <div className="user-ven-detail">
          <VendorProfileReactivation
            notify={"Are you sure you want to reactivate this user's profile?"}
            value={6}
            setToggle={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 4) {
      return (
        <div className="user-ven-detail">
          <UserControlLoginHistory setToggle={setUserControlToggle} />
        </div>
      );
    }
    if (toggle === 5) {
      return (
        <div className="display-deactivate-control close-deactivate">
          {" "}
          <VendorSuccessNotify
            notify={"User has been successfully deactivated"}
            handleReject={handleReject}
            reject={reject}
            deactivate={deactivate}
            setToggle={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 6) {
      return (
        <div className="display-deactivate-control close-deactivate">
          {" "}
          <VendorSuccessNotify
            notify={"User has been successfully reactivated"}
            handleReject={handleReject}
            setToggle={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 7) {
      return (
        <div className="user-ven-detail">
          <UserControlLoginHistory />
        </div>
      );
    }
    if (toggle === 8) {
      return (
        <div className="user-ven-detail">
          {" "}
          <UserControlDeleteModal
            content={"Are you sure you want to delete this Role"}
            value={11}
            handleReject={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 9) {
      return (
        <div className="kyc-ven-detail">
          {" "}
          <VendorProfileReactivation
            value={12}
            handleReject={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 10) {
      return (
        <div className="display-deactivate-control close-deactivate">
          {" "}
          <VendorSuccessNotify
            notify={"User details successfully updated"}
            setToggle={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 11) {
      return (
        <div className="display-deactivate-control close-deactivate">
          {" "}
          <VendorSuccessNotify
            notify={"You have successfully deleted the Role"}
            setToggle={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 12) {
      return (
        <div className="user-ven-detail">
          <UserControlLoginHistory />
        </div>
      );
    }
    if (toggle === 13) {
      return (
        <div className="display-deactivate-control close-deactivate">
          {" "}
          <AddNewUser />
        </div>
      );
    }
    if (toggle === 14) {
      return (
        <div className="display-deactivate-control close-deactivate">
          {" "}
          <UserControlOnboarding
            notify={
              "User successfully created. An onboarding mail has been sent to the user"
            }
            setToggle={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 15) {
      return (
        <div className="display-deactivate-control close-deactivate">
          {" "}
          <VendorSuccessNotify
            notify={"You have successfully deleted the Roles"}
            handleReject={handleReject}
          />
        </div>
      );
    }
    if (toggle === 16) {
      return (
        <div className="display-deactivate-control close-deactivate">
          {" "}
          <VendorSuccessNotify
            notify={"Password reset link has been sent successfully"}
            handleReject={handleReject}
            setToggle={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 17) {
      return (
        <div className="display-deactivate-control close-deactivate">
          {" "}
          <RoleCreationError
            notify={`OOPS!!! something went wrong, Please try again`}
            setToggle={setUserControlToggle}
            close="close"
          />
        </div>
      );
    }
  };

  function renderTable() {
    if (activeCard === 0) {
      return <Kycimg />;
    } else if (activeCard === "1") {
      return <Users />;
    } else if (activeCard === "2") {
      return <RolesPermission />;
    }
  }

  return (
    <>
      {roleLoading && (
        <div className="user-preloader">
          <Spinner />
        </div>
      )}
      {cardSize ? (
        <div className="control-card-cont">
          {prps.map((item, index) => (
            <div
              className="control-card"
              key={index}
              onClick={() => handleActive(item.id, item.name)}
            >
              <p style={{ width: "70%" }}>{item.name}</p>
              <div className="record-box">
                <h2>{item.num}</h2>
                <p>{item.numText}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="control-card-cont-2">
          {prps.map((item, index) => (
            <div
              className={
                activeCard === item.id
                  ? `control-card-2 active`
                  : `control-card-2`
              }
              key={index}
              onClick={() => handleActive(item.id, item.name)}
            >
              <p style={{ width: "70%", marginTop: "8px" }}>{item.name}</p>

              <div className="record-box1">
                <h2>{item.num}</h2>
              </div>
            </div>
          ))}
        </div>
      )}
      {handleToggle()}
      {renderTable()}
    </>
  );
}
