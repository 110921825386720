import { useState, useEffect } from "react";
import Moment from "react-moment";
import "../../css/user-control/users.css";
import KycInput from "../input/input";
import kimg from "../../img/ksearch.svg";
import plus from "../../img/plus.svg";
// import UserControlModalUserAction from "./user-modal/user-action-modal";
import Pagination from "../footer-pagination";
import UserDropDown from "./user-modal/user-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userControlActions } from "../../state/actions";

// let pageSize = 6;

function Users() {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { FetchUsers, searchUsers, setUserControlToggle } = bindActionCreators(
    userControlActions,
    dispatch
  );

  useEffect(() => {
    FetchUsers(currentPage);

    //eslint-disable-next-line
  }, [currentPage]);

  const { users, usersMeta, searchUserData, searchUsersMeta } = useSelector(
    (state) => state.userControl
  );

  const { per_page, total } = searchUsersMeta ? searchUsersMeta : usersMeta;

  let userData = searchUserData !== null ? searchUserData : users;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="user-cont">
        <div>
          <button
            className="user-btn-primary"
            onClick={() => {
              setUserControlToggle(13);
            }}
          >
            <img src={plus} alt="plus icon" />
            New User
          </button>
        </div>
        <div className="control-user-kscont">
          <KycInput label="Search Records" searchValue={searchUsers} />
          <img src={kimg} alt="search icon" />
        </div>
      </div>

      <div>
        <div className="user-table-content">
          <table className="control-table">
            <tr>
              <td></td>
              <td>Name</td>
              <td>Email</td>
              <td>Role</td>
              <td>Status</td>
              <td>Last Seen</td>
              <td>Date Enrolled</td>
            </tr>

            {userData.length > 0 ? (
              userData.map((item, idx) => (
                <tr className="user-item-tr" key={idx}>
                  <td>{(currentPage - 1) * per_page + 1 + idx}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.roles.map((i) => i.name).join(", ")}</td>
                  <td
                    className={
                      item.status === "active" ? "green" : "text-danger"
                    }
                  >
                    {item.status.toUpperCase()}
                  </td>
                  <td>{item.lastSeen || "-"}</td>
                  <td>
                    <Moment format="DD/MM/YYYY">{item.created_at}</Moment>
                  </td>
                  <td>
                    <UserDropDown
                      id={item.id}
                      status={item.status}
                      setToggle={setUserControlToggle}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-danger">No Record Found</td>
              </tr>
            )}
          </table>
        </div>

        {/* {toggle && <UserControlModalUserAction acceptToggle={acceptToggle} />} */}
        {userData.length > 0 && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
export default Users;
